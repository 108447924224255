// React
import { useEffect, useState } from "react";

// Hooks
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useLoading } from "../../../utils/contexts/LoadingContext";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";

// Components
import Page from "../page";
import ProfileSetup from "./initialSetup/ProfileSetup";
import RestaurantSetup from "./initialSetup/RestaurantSetup";
import { FAQ } from "./help/faq";
import { Features } from "./help/features";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Card } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";
import {
  Building2,
  CreditCard,
  Settings as SettingsIcon,
  User,
  HelpCircle,
} from "lucide-react";
import Subscription from "./initialSetup/Subscription";
import ChangePassword from "../../Auth/ChangePassword";
import Toaster from "../../../components/reusable/Toaster";
// Additional UI
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Glossary } from "./help/glossary";
import Guide from "./help/guide";
import Loading from "../../../components/reusable/loading/loading";

interface ImportDialogProps {
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ImportDialog: React.FC<ImportDialogProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription className="pt-2">{description}</DialogDescription>
      </DialogHeader>
      <DialogFooter className="mt-3">
        <Button onClick={onConfirm}>Confirmar</Button>
        <Button className="bg-red-500 hover:bg-red-600" onClick={onCancel}>
          Cancelar
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

const RestSettings = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();
  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "PROFILE RESTAURANT PAGE",
  });
  const location = useLocation();
  const tabFromState = (location.state as { tab?: string })?.tab || "profile";
  const { loading, setLoading } = useLoading();
  const [isDialogIngrOpen, setIsDialogIngrOpen] = useState(false);
  const [isDialogRecipeOpen, setIsDialogRecipeOpen] = useState(false);
  const { post } = useApi();

  const handleImport = async (isIngredients: boolean) => {
    setLoading(true);
    const url = isIngredients ? "/import_ingreds/" : "/import_recipes/";
    try {
      post(url, {}); //empty data
      Toaster.show(
        isIngredients
          ? "Ingredientes importados serão disponibilizados para consulta assim que possível."
          : "Receitas importadas serão disponibilizadas para consulta assim que possível.",
        "success"
      );
      isIngredients ? setIsDialogIngrOpen(false) : setIsDialogRecipeOpen(false);

      // if (response.status === 200 || response.status === 201) {
      //   const { message, inserted_count, duplicates_count } = response.data;
      //   console.log("Import response:", response.data);

      //   // If the server returned inserted/duplicates counts, build a more detailed success message
      //   if (inserted_count !== undefined && duplicates_count !== undefined) {
      //     const kind = isIngredients ? "Ingredientes" : "Receitas";
      //     Toaster.show(
      //       `${message}\n` +
      //       `${kind} novos: ${inserted_count}\n` +
      //       `${kind} duplicados (não adicionados): ${duplicates_count}`,
      //       "success"
      //     );
      //   } else {
      //     // Fallback if server doesn't return those fields
      //     Toaster.show(
      //       isIngredients ? "Ingredientes importados com sucesso." : "Receitas importadas com sucesso.",
      //       "success"
      //     );
      //   }
      // } else {
      //   Toaster.show(
      //     isIngredients
      //       ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
      //       : "Erro ao importar receitas. Por favor, tente mais tarde.",
      //     "error"
      //   );
      // }
    } catch (error) {
      console.error("Error:", error);
      Toaster.show(
        isIngredients
          ? "Erro ao importar ingredientes. Por favor, tente mais tarde."
          : "Erro ao importar receitas. Por favor, tente mais tarde.",
        "error"
      );
    }
    setLoading(false);
  };

  const sections = [
    { id: "features", name: "Funcionalidades" },
    { id: "faq", name: "FAQs" },
    { id: "guide", name: "Guia de Utilização" },
    { id: "glossary", name: "Glossário" },
  ];

  const [activeSection, setActiveSection] = useState("features");

  useEffect(() => {
    console.log("Active section:", activeSection);
  }, [activeSection]);

  return (
    <Page title={"Profile"}>
      <div className="container mx-auto py-10 px-4">
        <Tabs defaultValue={tabFromState} className="space-y-6">
          <TabsList className="grid w-full grid-cols-5 h-auto p-1 bg-white">
            <TabsTrigger
              value="profile"
              className="flex items-center gap-2 py-2"
            >
              <User className="h-4 w-4" />
              <span>Utilizador</span>
            </TabsTrigger>
            <TabsTrigger
              value="restaurant"
              className="flex items-center gap-2 py-2"
            >
              <Building2 className="h-4 w-4" />
              <span>Restaurante</span>
            </TabsTrigger>
            <TabsTrigger
              value="subscription"
              className="flex items-center gap-2 py-2"
            >
              <CreditCard className="h-4 w-4" />
              <span>Subscrição</span>
            </TabsTrigger>
            <TabsTrigger
              value="settings"
              className="flex items-center gap-2 py-2"
            >
              <SettingsIcon className="h-4 w-4" />
              <span>Configurações</span>
            </TabsTrigger>
            <TabsTrigger value="help" className="flex items-center gap-2 py-2">
              <HelpCircle className="h-4 w-4" />
              <span>Ajuda</span>
            </TabsTrigger>
          </TabsList>

          {/* 1) Profile */}
          <TabsContent value="profile" className="space-y-6">
            <Card className="p-6">
              <ProfileSetup />
            </Card>
          </TabsContent>

          {/* 2) Restaurant */}
          <TabsContent value="restaurant" className="space-y-6">
            <Card className="p-6">
              <RestaurantSetup />
            </Card>
          </TabsContent>

          {/* 3) Subscription */}
          <TabsContent value="subscription" className="space-y-6">
            <Subscription />
          </TabsContent>

          {/* 4) Settings */}
          <TabsContent value="settings" className="space-y-6">
            <Card className="p-6">
              <h2 className="text-xl font-semibold mb-6">Configurações</h2>
              <Accordion type="single" collapsible className="w-full">
                {/* A) Alterar Password */}
                <AccordionItem value="password">
                  <AccordionTrigger>Alterar Password</AccordionTrigger>
                  <AccordionContent>
                    <ChangePassword />
                  </AccordionContent>
                </AccordionItem>

                {/* B) Importar Ingredientes */}
                <AccordionItem value="ingredients">
                  <AccordionTrigger>Importar Ingredientes</AccordionTrigger>
                  <AccordionContent>
                    <Dialog
                      open={isDialogIngrOpen}
                      onOpenChange={setIsDialogIngrOpen}
                    >
                      <DialogTrigger asChild>
                        <Button onClick={() => setIsDialogIngrOpen(true)}>
                          Importar Ingredientes
                        </Button>
                      </DialogTrigger>
                      {/* Reuse the old confirm/cancel approach */}
                      <ImportDialog
                        title="Importar Ingredientes"
                        description="Estará a importar ingredientes para o menu com respetivos produtos de mercado associados. Deseja continuar?"
                        onConfirm={() => {
                          handleImport(true);
                        }}
                        onCancel={() => setIsDialogIngrOpen(false)}
                      />
                    </Dialog>
                  </AccordionContent>
                </AccordionItem>

                {/* C) Importar Receitas */}
                <AccordionItem value="recipes">
                  <AccordionTrigger>Importar Receitas</AccordionTrigger>
                  <AccordionContent>
                    <Dialog
                      open={isDialogRecipeOpen}
                      onOpenChange={setIsDialogRecipeOpen}
                    >
                      <DialogTrigger asChild>
                        <Button onClick={() => setIsDialogRecipeOpen(true)}>
                          Importar Receitas
                        </Button>
                      </DialogTrigger>
                      <ImportDialog
                        title="Importar Receitas"
                        description="Estará a importar receitas para o menu. Deseja continuar?"
                        onConfirm={() => {
                          handleImport(false);
                        }}
                        onCancel={() => setIsDialogRecipeOpen(false)}
                      />
                    </Dialog>
                  </AccordionContent>
                </AccordionItem>

                {/* D) Escolher Receitas */}
                <AccordionItem value="choose-recipes">
                  <AccordionTrigger>Escolher Receitas</AccordionTrigger>
                  <AccordionContent>
                    {/* A simple Link to /app/menu/rest-select-prods or a button */}
                    <Button
                      onClick={() => {
                        navigate("/app/menu/rest-select-prods");
                      }}
                    >
                      Ir para Escolher Receitas
                    </Button>
                    {/* OR do <Link to="/app/menu/rest-select-prods">Escolher Receitas</Link> */}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </Card>
          </TabsContent>

          {/* 5) Help */}
          <TabsContent value="help" className="space-y-6">
            <div className="flex gap-2">
              <div className="min-w-56 bg-white shadow-md rounded-md">
                <div className="p-2">
                  <ul>
                    {sections.map((section) => (
                      <li key={section.id} className="mb-2">
                        <button
                          className={`w-full flex justify-start px-4 py-2 rounded-md transition-all ease-in-out duration-200 ${
                            activeSection === section.id
                              ? "bg-_secondary text-white"
                              : "hover:bg-gray-100"
                          }`}
                          onClick={() => setActiveSection(section.id)}
                        >
                          {section.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full">
                {activeSection === "faq" && <FAQ />}
                {activeSection === "features" && <Features />}
                {activeSection === "guide" && <Guide />}
                {activeSection === "glossary" && <Glossary />}
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Loading isOpen={loading} />
    </Page>
  );
};
export default RestSettings;
