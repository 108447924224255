// React
import React, { useEffect, useState } from "react";

// contexts
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import { useLoading } from "../../../utils/contexts/LoadingContext";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import InfoComponent from "../../../components/reusable/info/info.component";
import Toaster from "../../../components/reusable/Toaster";

// icons / images
import {
  exportToPDF,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { IoIosSearch } from "react-icons/io";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import SelectComponent from "../../../components/reusable/select/select";
import { Label } from "../../../components/ui/label";


const periodFormatter = {
  getAll: () => ["2 semanas", "1 mês", "3 meses", "6 meses"],
  format: (value: string) => value,
  default: "2 semanas",
  getWeeks: (value: string) => {
    switch (value) {
      case "2 semanas":
        return 2;
      case "1 mês":
        return 4;
      case "3 meses":
        return 12;
      case "6 meses":
        return 24;
      default:
        return 2;
    }
  },
};

const Planning = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "PLANNING PAGE",
  });

  const { get } = useApi();
  const [period, setPeriod] = useState(false); // false for immediate, true for homologous
  const [timePeriod, setTimePeriod] = useState("2 semanas");

  const [tableData, setTableData] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  });

  const [tableDataFilter, setTableDataFilter] = useState({
    q1: null,
    q2: null,
    q3: null,
    q4: null,
  });

  const [lastSalesDate, setLastSalesDate] = useState(null);
  const { loading, setLoading } = useLoading();
  const [searchQueries, setSearchQueries] = useState({
    q1: "",
    q2: "",
    q3: "",
    q4: "",
  });

  const titles = {
    q1: "Quantidade de doses a considerar na estimativa de compras",
    q2: "Quantidades usadas nas receitas - Valores médios de PVP e estimativa de custo",
    q3: "Ingredientes - Por SKU de referência",
    q4: "Ingredientes usados em receitas - Os 3 PVP's mais baixos",
  };

  const columnDefinitions = {
    q1: [
      { headerName: "Receita", field: "receita" },
      { headerName: "Descrição", field: "rest_prod_desc" },
      { headerName: "Doses", field: "doses" },
    ],
    q2: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Preço médio", field: "price_avg" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],
    q3: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "detailed_sku_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
    ],
    q4: [
      { headerName: "Ingrediente", field: "ingr_name" },
      { headerName: "Quantidade(kg)", field: "qt_ingr_kg" },
      { headerName: "Detalhes", field: "detailed_sku_name" },
      { headerName: "Fornecedor", field: "sku_prov_name" },
      { headerName: "Categoria", field: "sku_category" },
      { headerName: "Preço", field: "price" },
      { headerName: "Data do Preço", field: "price_date" },
      { headerName: "Custo Estimado", field: "custo_estimado" },
      { headerName: "Rank", field: "rank" },
    ],
  };

  useEffect(() => {
    const fetchSalesLastDate = async () => {
      try {
        const result = await get("dash4_sales_last_date");
        setLastSalesDate(result.data);
      } catch (error) {
        console.error("Error fetching sales_last_date:", error);
        Toaster.show("Erro ao carregar a última data de vendas", "error");
      }
    };
  
    fetchSalesLastDate();
  }, []);
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoints = ["d9_q1", "d9_q2", "d9_q3", "d9_q4"];
        const results = await Promise.allSettled(
          endpoints.map((endpoint) => get(`${endpoint}?timePeriod=${period ? 0 : periodFormatter.getWeeks(timePeriod)}`))
        );
  
        console.log("results", results);
  
        let errorMessages = "Não existem dados para:\n";
  
        results.forEach((result, index) => {
          const key = `q${index + 1}`;
          if (result.status === "fulfilled") {
            if (result.value.data.length === 0) {
              errorMessages += `-> ${titles[key]}\n`;
            }
            setTableData((prev) => ({ ...prev, [key]: result.value.data }));
            setTableDataFilter((prev) => ({
              ...prev,
              [key]: result.value.data,
            }));
          } else {
            console.error(`Error fetching ${endpoints[index]}:`, result.reason);
            setTableData((prev) => ({ ...prev, [key]: null }));
            setTableDataFilter((prev) => ({ ...prev, [key]: null }));
            errorMessages += `-> ${titles[key]}\n`;
          }
        });
  
        if (errorMessages !== "Não existem dados para:\n") {
          Toaster.show(errorMessages.slice(0, -1), "error"); // Remove last \n\n
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        Toaster.show("Erro ao carregar dados", "error");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [timePeriod, period]);
  

  useEffect(() => {
    const updateTableDataFilter = (key) => {
      if (!tableData || !tableData[key]) return;

      switch (key) {
        case "q1":
          setTableDataFilter((prev) => ({
            ...prev,
            q1: tableData.q1.filter((item) =>
              item.receita
                .toLowerCase()
                .includes(searchQueries.q1.toLowerCase())
            ),
          }));
          break;
        case "q2":
          setTableDataFilter((prev) => ({
            ...prev,
            q2: tableData.q2.filter((item) =>
              item.ingr_name
                .toLowerCase()
                .includes(searchQueries.q2.toLowerCase())
            ),
          }));
          break;
        case "q3":
          setTableDataFilter((prev) => ({
            ...prev,
            q3: tableData.q3.filter((item) =>
              item.ingr_name
                .toLowerCase()
                .includes(searchQueries.q3.toLowerCase())
            ),
          }));
          break;
        case "q4":
          setTableDataFilter((prev) => ({
            ...prev,
            q4: tableData.q4.filter((item) =>
              item.ingr_name
                .toLowerCase()
                .includes(searchQueries.q4.toLowerCase())
            ),
          }));
          break;
        default:
          break;
      }
    };

    Object.keys(searchQueries).forEach((key) => {
      updateTableDataFilter(key);
    });
  }, [searchQueries, tableData]);

  const renderSearchBar = (key) => (
    <div className="flex gap-2">
      <Input
        type="text"
        name="search"
        placeholder="Pesquisar"
        className="w-80 h-10"
        value={searchQueries[key]}
        onChange={(e) =>
          setSearchQueries((prev) => ({ ...prev, [key]: e.target.value }))
        }
      />
      <Button type="submit">
        <IoIosSearch className="text-white" size={20} />
      </Button>
    </div>
  );

  const renderTable = (key) => (
    <div>
      <h3 className="text-2xl font-semibold text-blue-dark">{titles[key]}</h3>
      <hr className="my-3" />
      <div className="pb-4 flex gap-2 justify-between items-center">
        {renderSearchBar(key)}
        <Button
          className="cursor-pointer"
          onClick={() =>
            exportToPDF(
              columnDefinitions[key],
              tableDataFilter[key],
              titles[key]
            )
          }
        >
          Exportar PDF
        </Button>
      </div>
      <div className="overflow-x-auto">
        {tableDataFilter[key] && (
          <Table className="min-w-full">
            {renderTableHeader(
              columnDefinitions[key].map((col) => col.headerName)
            )}
            {renderTableBody(
              columnDefinitions[key].map((col) => col.field),
              tableDataFilter[key]
            )}
          </Table>
        )}
      </div>
    </div>
  );

  const renderTableHeader = (columnNames: string[]) => (
    <TableHeader>
      <TableRow>
        {columnNames.map((header) => (
          <TableCell key={header}>{header}</TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );

  const renderTableBody = (fields: string[], data: any[]) => (
    <TableBody>
      {data.map((item, index) => {
        const getBackgroundColor = (rank) => {
          switch (rank) {
            case 1:
              return "rgba(144, 238, 144, 0.5)";
            case 2:
              return "rgba(173, 255, 47, 0.5)";
            case 3:
              return "rgba(255, 238, 140, 0.5)";
            default:
              return "";
          }
        };
  
        return (
          <TableRow
            key={index}
            style={{ backgroundColor: getBackgroundColor(item.rank) }}
          >
            {fields.map((field, fieldIndex) => {
              if (field === "sku_prod_name") {
                return (
                  <TableCell title={item.concatenated_field} key={fieldIndex}>
                    {item.sku_prod_name}
                  </TableCell>
                );
              }
  
              let value = item[field];
              if (
                (field.includes("price") || field.includes("custo")) &&
                !field.includes("date")
              ) {
                value = `${Number(value).toFixed(2)}€`;
              }
  
              return (
                <TableCell
                  key={fieldIndex}
                  className="px-6 py-2 whitespace-no-wrap text-gray-600"
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );

  return (
    <Page title={"Planeamento de Compras"} loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] m-auto text-black">
          {/* Header Section */}
          <div className="flex items-center mt-5">
            <CardHeader>
              <CardTitle className="text-4xl font-semibold text-blue-dark">
                Planeamento de Compras
              </CardTitle>
              <CardDescription>
                Planeamento de compras com base em vendas passadas e previsões
                de vendas futuras.
              </CardDescription>
            </CardHeader>
          </div>
          {lastSalesDate && (
            <InfoComponent>
              <p>
                Dados disponíveis até a data:{" "}
                {new Date(lastSalesDate).toLocaleDateString("pt-PT", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </InfoComponent>
          )}
          <div>
          <InfoComponent>
            <div>
              <p>
                Selecione, para cada receita, o número de doses que deseja
                utilizar no planeamento.
              </p>
              <p>
                Deverá expecificar se pretente usar o período histórico imediato (mais recente)
                ou o período homólogo (mesmo período do ano anterior).
              </p>
              <p>
                No caso de escolher período histórico imediato deverá, também, indicar o período de tempo que pertende considerar.
              </p>
            </div>
          </InfoComponent>
          <div className="py-4">
            <div className="flex relative bg-zinc-200 w-fit rounded-full cursor-pointer">
              <div
                className={`rounded-full px-4 py-2 w-60 flex justify-center h-9 `}
                onClick={() => setPeriod(false)}
              >
                <p
                  className={`z-[2] text-sm transition-colors duration-500 ease-in-out  ${!period ? "text-gray-200" : "text-gray-800"
                    }`}
                >
                  Período histórico imediato
                </p>
              </div>
              <div
                className={`rounded-full px-4 py-2 w-60 flex justify-center h-9 `}
                onClick={() => setPeriod(true)}
              >
                <p
                  className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${period ? "text-gray-200" : "text-gray-800"
                    }`}
                >
                  Período homólogo
                </p>
              </div>
              <div
                className={`absolute bg-gray-900 top-0 w-60 h-9 rounded-full z-[1] transition-all duration-500 ease-in-out ${!period ? "translate-x-0" : "translate-x-[100%]"
                  }`}
              ></div>
            </div>
          </div>
          <div
            className={`pb-2 transition-all duration-200 ${period ? "opacity-0 h-0 overflow-hidden" : "opacity-100 h-auto"
              }`}
          >
            <Label>Período a Considerar</Label>
            <SelectComponent
              formatter={periodFormatter}
              darkMode={false}
              onChange={(value: string) => {
                setTimePeriod(value);
              }}
              value={timePeriod}
            />
          </div>
        </div>
          <div className="flex gap-20 flex-col mt-10">
            {Object.keys(tableData).map((key) => (
              <React.Fragment key={key}>
                {tableData[key] &&
                  tableData[key].length > 0 &&
                  renderTable(key)}
              </React.Fragment>
            ))}
          </div>
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default Planning;
