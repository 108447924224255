import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Card } from "../../../../components/ui/card";

const features = [
  {
    title: "Recolha de Dados – Comece pelo Essencial",
    description:
      "O AI4Chef inclui um conjunto de processos automáticos de recolha e tratamento de informação que permitem dar aos utilizadores acesso a informação de preços de milhares de produtos disponíveis no mercado, permitindo decisões rápidas e informadas sobre ingredientes e pratos. Actualize-se diariamente com informações que farão a diferença na sua cozinha e no seu negócio.",
  },
  {
    title: "Mercado – Monitorize os Preços como um Especialista",
    description: [
      {
        text: "Visão Geral de Preços",
        detail: "Tenha uma visão completa do mercado em tempo real.",
      },
      {
        text: "Preços do Seu Cabaz",
        detail:
          "Personalize e acompanhe os preços dos produtos usados no seu restaurante.",
      },
      {
        text: "Custos de Receitas e Ingredientes",
        detail:
          "Controle os custos detalhados das suas receitas para garantir que nenhuma margem seja desperdiçada.",
      },
    ],
  },
  {
    title: "Performance – Meça, Analise e Evolua",
    description: [
      {
        text: "Análise de Vendas",
        detail:
          "Entenda a evolução das suas vendas no curto e no longo prazo, comparando períodos e identificando tendências.",
      },
      {
        text: "Visão dos Custos de Produção",
        detail:
          "Saiba exatamente quanto custa cada prato e tome decisões estratégicas.",
      },
      {
        text: "Margem Bruta",
        detail:
          "Descubra quais pratos são verdadeiras estrelas de lucro para o seu negócio.",
      },
    ],
  },
  {
    title: "Estratégia – Planear para o Sucesso",
    description: [
      {
        text: "Simulador de Preços",
        detail:
          "Teste diferentes markups e encontre a estratégia perfeita para seu menu.",
      },
      {
        text: "Projeções de Futuro",
        detail:
          "Preveja preços, vendas e margens a curto prazo com base em cenários realistas.",
      },
    ],
  },
  {
    title: "Planeamento – Esteja Sempre à Frente",
    description: [
      {
        text: "Plano de Compras Linear",
        detail:
          "Baseado no histórico de vendas, crie um plano claro para evitar desperdícios e faltas de stock.",
      },
      {
        text: "Plano de Compras Específico",
        detail:
          "Ajuste suas compras para eventos especiais, como jantares em grupo.",
      },
    ],
  },
  {
    title: "Investigação – Explore Novas Oportunidades",
    description: [
      {
        text: "Análise de Clientes",
        detail:
          "Obtenha insights sobre os seus clientes e crie estratégias direcionadas.",
      },
      {
        text: "Análise de Eventos",
        detail:
          "Avalie o impacto de mudanças e iniciativas de gestão no seu negócio (vendas, volumes processados, e outros indicadores à sua escolha). Use os dados e as capacidades analíticas AI4chef para esclarecer se as alterações tiveram efeitos reais no negócio.",
      },
    ],
  },
  {
    title: "Gestão Técnica – Mantenha Tudo em Ordem",
    description: [
      {
        text: "Upload de Dados de Vendas",
        detail: "Integre facilmente suas vendas usando o formato SAFT.",
      },
      {
        text: "Gestão de Receitas e Ingredientes",
        detail:
          "Atualize, edite e valide receitas e ingredientes, assegurando precisão e consistência.",
      },
      {
        text: "Mapeamento e Reprocessamento de Dados",
        detail:
          "Faça ajustes rápidos e sincronize todas as análises para refletir mudanças em tempo real.",
      },
    ],
  },
  {
    title: "Configuração – Personalize para o Seu Negócio",
    description: [
      {
        text: "Importação de Ingredientes e Receitas",
        detail:
          "Economize tempo com a configuração inicial, importando dados diretamente do repositório AI4Chef.",
      },
      {
        text: "Categorias e Mapeamento de Produtos",
        detail:
          "Configure suas categorias e associe produtos a receitas com facilidade.",
      },
    ],
  },
  {
    title: "Ajuda – Sempre ao Seu Lado",
    description: [
      {
        text: "Suporte Direto",
        detail:
          "Abra tickets para resolver dúvidas ou obter suporte personalizado.",
      },
      {
        text: "Guias e Perguntas Frequentes",
        detail:
          "Tenha acesso ao conhecimento disponível e às boas práticas empresariais.",
      },
      {
        text: "Glossário Técnico",
        detail:
          "Entenda cada funcionalidade com explicações claras e objetivas.",
      },
    ],
  },
  {
    title: "Conta – Gestão Simples e Transparente",
    description: [
      {
        text: "Subscrição e Perfil",
        detail:
          "Veja todas as informações importantes do seu restaurante e usuário.",
      },
      {
        text: "Segurança",
        detail: "Altere sua senha e gerencie sessões com facilidade.",
      },
    ],
  },
];

export function Features() {
  return (
    <Card className="p-6">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold text-blue-dark">
          Transforme seu Restaurante com o Poder da IA – Descubra o AI4Chef!
        </CardTitle>
        <CardDescription>
          Bem-vindo ao futuro da gestão de restaurantes e hospitalidade! O{" "}
          <strong>AI4Chef</strong> é a ferramenta definitiva para chefs e
          gestores visionários que valorizam decisões informadas e querem usar{" "}
          <strong>Inteligência Artificial (IA)</strong> para maximizar o
          potencial dos seus negócios. Se acredita no poder dos dados para
          revolucionar o sector da restauração, o AI4Chef foi criado
          especialmente para si.
        </CardDescription>
      </CardHeader>
      <hr className="border-t border-_secondary my-8" />
      <>
        <div className="bg-white rounded-lg mb-4">
          <CardTitle className="text-xl font-semibold text-blue-dark">
            Porquê adoptar o AI4Chef?
          </CardTitle>
          <CardDescription>
            A nossa missão é simples:{" "}
            <strong>
              capacitar chefs e gestores a tomarem decisões inteligentes e
              eficientes
            </strong>{" "}
            com base em dados confiáveis. Preços, compras, margens,
            comportamento dos clientes e estratégias de marketing podem ser
            optimizadas com dos seus dados e da tecnologia de ponta AI4Chef .
            Estamos aqui para ajudá-lo a transformar dados em inovação, sucesso
            e lucro.
          </CardDescription>
        </div>
        <hr className="border-t border-_secondary my-8" />
        <div className="border-1 border-gray-200 bg-white rounded-lg mb-4">
          <CardTitle className="text-2xl font-semibold text-blue-dark">
            Funcionalidades Inovadoras do AI4Chef
          </CardTitle>
          <div className="flex flex-col gap-4 mt-4">
            {features.map((feature, index) => (
              <div key={index}>
                <CardTitle className="text-sm font-semibold text-blue-dark">
                  {feature.title}
                </CardTitle>
                <CardDescription className="text-justify text-sm">
                  {Array.isArray(feature.description) ? (
                    <ul>
                      {feature.description.map((item, i) => (
                        <li key={i} className="has-line-data">
                          <strong>{item.text}</strong>: {item.detail}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    feature.description
                  )}
                </CardDescription>
                <hr className="border-t border-light mt-4" />
              </div>
            ))}
          </div>
        </div>

        <hr className="border-t border-_secondary my-8" />

        <div className="bg-white rounded-lg mb-4">
          <CardTitle className="text-xl font-semibold text-blue-dark">
            Por que Esperar? O Futuro do Seu Restaurante Começa Hoje!
          </CardTitle>
          <CardDescription>
            O AI4Chef é mais do que uma ferramenta – é um parceiro estratégico
            que transforma dados em crescimento e eficiência. Seja um chef
            criativo ou um gestor analítico, o AI4Chef oferece{" "}
            <strong>inteligência prática e visão de negócios</strong> para que
            se foque no que realmente importa:{" "}
            <strong>
              encantar seus clientes e crescer de forma sustentável
            </strong>
            . 📈{" "}
            <strong>
              Venha fazer parte da revolução data-driven na restauração com o
              AI4Chef!
            </strong>
          </CardDescription>
        </div>
      </>
    </Card>
  );
}
