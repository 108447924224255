import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../utils/contexts/UserContext";
import { AiFillHome } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import Toaster from "./Toaster";
import {
  FaRegClipboard,
  FaShoppingBasket,
  FaChartLine,
  FaMoneyBillAlt,
  FaUtensils,
  FaClipboardCheck,
  FaCheckCircle,
  FaUser,
} from "react-icons/fa";
import {
  MdEvent,
  MdAttachMoney,
  MdTrendingUp,
  MdPersonSearch,
  MdCreateNewFolder,
  MdAddCircleOutline,
  MdSearch,
  MdSavedSearch,
} from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { CgShoppingCart } from "react-icons/cg";

import {
  GiCookingPot,
  GiMoneyStack,
  GiCook,
  GiRecycle,
  GiBookCover,
  GiArtificialIntelligence,
} from "react-icons/gi";

import { FiCalendar } from "react-icons/fi";

import {
  IoIosHelpCircleOutline,
  IoMdArrowBack,
  IoMdArrowDropdown,
  IoIosChatbubbles,
} from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { GrDocumentPerformance } from "react-icons/gr";
import { PiStrategy } from "react-icons/pi";

import "../../assets/css/customScroll.styles.css";
import { RxHamburgerMenu } from "react-icons/rx";
import "./sidebar.css";
import { IoFastFoodOutline, IoPricetagsOutline } from "react-icons/io5";
import { axiosPrivate } from "../../utils/api/axios";
import { Button } from "../ui/button";
import { LogOut } from "lucide-react";
import React from "react";
import { useLoading } from "../../utils/contexts/LoadingContext";

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const { user, groups } = useUser();
  const navigate = useNavigate();
  const imageUrl = `${process.env.PUBLIC_URL}/logo.png`;
  const location = useLocation();
  const [openTab, setOpenTab] = useState<string | null>(null);
  const { setLoading } = useLoading();
  const profileRef = useRef(null);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axiosPrivate.post("logout", {
        headers: { "Content-Type": "application/json" },
      });
      sessionStorage.clear();
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      console.error("Logout failed", error);
      setLoading(false);
    }
  };

  const mainTabs = [
    {
      label: "Mercado",
      icon: <IoPricetagsOutline className="w-5 h-5 rounded" />,
      items: [
        { to: "/app/general", label: "Preços Mercado", icon: FaRegClipboard },
        {
          to: "/app/ownbasket",
          label: "Preços Restaurante",
          icon: FaShoppingBasket,
        },
        {
          to: "/app/foodcost/recipes",
          label: "Custos Receitas e Ingredientes",
          icon: FaUtensils,
        },
        {
          to: "/app/foodcostAnalysis",
          label: "Análise de Food Cost",
          icon: GiCookingPot,
        },

      ],
    },
    {
      label: "Performance",
      icon: <GrDocumentPerformance className="w-5 h-5 rounded" />,
      items: [
        { to: "/app/margin", label: "Margem Bruta", icon: GiMoneyStack },
        {
          to: "/app/sales/short",
          label: "Vendas Curto Prazo",
          icon: MdAttachMoney,
        },
        {
          to: "/app/sales/long",
          label: "Vendas Longo Prazo",
          icon: MdTrendingUp,
        },
      ],
    },
    {
      label: "Planeamento",
      icon: <FiCalendar className="w-5 h-5 rounded" />,
      items: [
        {
          to: "/app/planning",
          label: "Plano de Compras",
          icon: FiCalendar,
        },
        {
          to: "/app/planning/create",
          label: "Criar Planeamento",
          icon: MdCreateNewFolder,
        },
      ],
    },
    {
      label: "Estratégia",
      icon: <PiStrategy className="w-5 h-5 rounded" />,
      items: [
        {
          to: "/app/customers",
          label: "Análise Clientes",
          icon: MdPersonSearch,
        },
        { to: "/app/events", label: "Análise Eventos", icon: MdEvent },
        { to: "/app/forecasts", label: "Previsões", icon: FaChartLine },
        {
          to: "/app/pricing",
          label: "Preços de Pratos",
          icon: FaMoneyBillAlt,
        },
      ],
    },
    {
      label: "Gestão de Menu",
      icon: <IoFastFoodOutline className="w-5 h-5 rounded" />,
      items: [
        {
          to: "/app/recipeAI",
          label: "Assistente AI",
          icon: GiArtificialIntelligence,
        },
        { to: "/app/menu", label: "Gerir Receitas", icon: GiBookCover },

        {
          to: "/app/menu/ingred/create",
          label: "Ingredientes",
          icon: MdAddCircleOutline,
        },
        {
          to: "/app/menu/reprocess_ingreds",
          label: "Reprocessar Ingredientes",
          icon: GiRecycle,
        },
        {
          to: "/app/menu/reprocess_recipes",
          label: "Reprocessar Receitas",
          icon: GiCook,
        },
        {
          to: "/app/menu/validate_recipes",
          label: "Validar Receitas",
          icon: FaCheckCircle,
          //requiresBackoffice: true,
        },
        { to: "/app/consultas", label: "Consultas", icon: MdSearch },
      ],
    },
    {
      label: "Gestão de Compras",
      icon: <CgShoppingCart className="w-5 h-5 rounded" />,
      items: [
        {
          to: "/app/invoices",
          label: "Adicionar Faturas",
          icon: FaFileInvoiceDollar,
        },
        {
          to: "/app/providers",
          label: "Adicionar Fornecedores",
          icon: FaFileInvoiceDollar,
        },
        {
          to: "/app/costbytype",
          label: "Análise de Custos por Tipo de Compra",
          icon: FaFileInvoiceDollar,
        },
        {
          to: "/app/costsuppliersvsmarketprices",
          label: "Análise Compras de Ingredientes",
          icon: FaFileInvoiceDollar,
        },
        {
          to: "/app/purchasesresale",
          label: "Análise de Compras de Produtos para Revenda",
          icon: FaFileInvoiceDollar,
        },
      ],
    },
  ];

  const backOfficeTab = {
    label: "Back Office",
    icon: <RiAdminLine className="w-5 h-5 rounded" />,
    items: [
      {
        to: "/app/dataFilling",
        label: "Dados em Falta",
        icon: FaClipboardCheck,
      },
      {
        to: "/app/consultasBack",
        label: "Consultas-BO",
        icon: MdSavedSearch,
      },
    ],
  };

  const navItems = [...mainTabs];
  if (groups?.includes("backoffice")) {
    navItems.push(backOfficeTab);
  }

  const renderNavItems = (items) => {
    const filteredItems = items.filter((item) => {
      if (item.requiresBackoffice && !groups?.includes("backoffice")) {
        return false;
      }
      return true;
    });
    const matchingItems = items.filter((item) => {
      const regex = new RegExp(`^${item.to}(/|$)`);
      return regex.test(location.pathname);
    });
    const activeItem = matchingItems.reduce(
      (prev, current) => (current.to.length > prev.to.length ? current : prev),
      { to: "" }
    );

    return filteredItems.map((item) => {
      const isActive = item.to && activeItem.to === item.to;
      const commonClasses = `px-4 py-3 flex gap-2 items-center text-xs ${
        isActive ? "bg-zinc-100" : "hover:bg-zinc-100"
      }`;

      // Check if the item has a 'to' property (navigation link)
      if (item.to) {
        return (
          <Link key={item.to} to={item.to}>
            <li className={commonClasses}>
              <item.icon className="w-4 h-4 rounded text-_secondary" />
              <p className="text-black">{item.label}</p>
            </li>
            <hr />
          </Link>
        );
      }
      // Check if the item has an 'action' property (button action)
      else if (item.action) {
        return (
          <li
            key={item.label}
            className={`${commonClasses} cursor-pointer`}
            onClick={item.action}
          >
            <item.icon className="w-4 h-4 rounded text-_secondary" />
            <p className="text-black">{item.label}</p>
            <hr />
          </li>
        );
      }
      // If the item doesn't have 'to' or 'action', render nothing or handle accordingly
      else {
        return null;
      }
    });
  };

  return (
    <>
      {user && (
        <>
          {/* top-right-corner */}
          <div className="fixed top-0 left-5 px-5 py-5">
            <button onClick={() => setIsSidebarOpen(true)}>
              <RxHamburgerMenu size={25} className="text-2xl cursor-pointer" />
            </button>
          </div>

          {/* top-right-corner */}
          <div className="absolute top-0 right-0 flex items-center space-x-5 p-4 z-999">
            <span className="bg-white rounded-xl py-2 px-4 text-xs border border-gray-300">
              {user.profile.restaurant.rest_name}
            </span>
            <div className="relative" ref={profileRef}>
              <div
                className="p-3 rounded-full bg-_secondary hover:bg-_primary transform transition-transform duration-300 hover:scale-110 transition-colors hover:cursor-pointer"
                onClick={() => navigate("/app/profile")}
              >
                <FaUser className="w-5 h-5 text-white" />
              </div>
            </div>
          </div>

          {/* bottom-right-corner */}
          <div className="fixed bottom-0 right-0 flex flex-col items-center gap-2 p-4 z-[1000] hover:cursor-pointer ">
            <div
              className="p-2 rounded-full bg-_secondary hover:bg-_primary transform transition-transform duration-300 hover:scale-110 transition-colors"
              onClick={() => {
                navigate("/app/profile", { state: { tab: "help" } });
              }}
            >
              <IoIosHelpCircleOutline
                title="Ajuda"
                className="w-8 h-8 hover:cursor-pointer text-white"
              />
            </div>
            <div
              className="p-2 rounded-full bg-_secondary hover:bg-_primary transform transition-transform duration-300 hover:scale-110 transition-colors"
              onClick={() => {
                navigate("/app/tickets");
              }}
            >
              <IoIosChatbubbles
                title="Suporte"
                className="w-8 h-8 hover:cursor-pointer text-white"
              />
            </div>
          </div>
          <nav
            className={`fixed top-0 left-0 bottom-0 w-72 bg-white shadow-md transform ${
              isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-700 ease-in-out`}
          >
            <div className="h-full flex flex-col">
              <div className="p-4 flex justify-between items-center">
                <div className="relative">
                  <Link to="/app/homepage" className="flex items-center">
                    <img
                      className="w-32 rounded-full"
                      src={imageUrl}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <IoMdArrowBack
                  size={20}
                  className="text-2xl cursor-pointer"
                  onClick={() => setIsSidebarOpen(false)}
                />
              </div>
              <ul className="flex-grow overflow-y-auto scroll pr-2 pb-4 mt-8">
                <Link to="/app/homepage">
                  <li
                    className={`px-4 py-3 flex gap-2 items-center ${
                      location.pathname === "/app/homepage"
                        ? "bg-zinc-100"
                        : "hover:bg-zinc-100"
                    }`}
                  >
                    <AiFillHome className="w-5 h-5 rounded text-_primary" />
                    <p className="text-black">Início</p>
                  </li>
                </Link>
                {navItems.map((tab, index) => (
                  <React.Fragment key={index}>
                    <li
                      className="px-4 py-3 flex gap-2 items-center cursor-pointer hover:bg-zinc-100"
                      onClick={() =>
                        setOpenTab(openTab === tab.label ? null : tab.label)
                      }
                    >
                      <span className="text-_primary">{tab.icon}</span>
                      <div className="flex gap-2 items-center justify-between w-full text-black text-sm">
                        <p>{tab.label}</p>
                        {openTab === tab.label ? (
                          <IoMdArrowDropup size={32} title="Close Menu" />
                        ) : (
                          <IoMdArrowDropdown size={32} title="Open Menu" />
                        )}
                      </div>
                    </li>
                    <div className="pl-2">
                      {openTab === tab.label && renderNavItems(tab.items)}
                    </div>
                  </React.Fragment>
                ))}
              </ul>
              <div className="mt-8 px-2 py-2">
                <Button
                  className="w-full bg-light hover:bg-_secondary border border-2 border-_primary text-_primary hover:text-white"
                  onClick={handleLogout}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Terminar Sessão
                </Button>
              </div>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default Sidebar;
