// React
import { useState, useEffect } from "react";
// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table";
import { motion } from "framer-motion";

import {
    CardDescription,
    CardHeader,
    CardTitle,
} from "../../../../components/ui/card";
import {
    DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import Modal from "../../../../components/reusable/Modal";


// Icons
import { IoAdd } from "react-icons/io5";
import { FaSearch, FaTrash } from "react-icons/fa";
import React from "react";

interface RecipeOption {
    descricao: string;
    ingredientes: string[];
}

interface RecipeOptions {
    classica: RecipeOption;
    economica: RecipeOption;
    sofisticada: RecipeOption;
}

const RecipeAiPage = () => {
    const [tokenVerified, setTokenVerified] = useState(false);

    // Callback function to update token verification status
    const handleTokenVerified = () => {
        setTokenVerified(true);
    };
    useTokenVerifier({
        onTokenVerified: handleTokenVerified,
        log: "RECIPES AI PAGE",
    });

    const { get, post } = useApi();
    const [data, setData] = useState(null);
    const { loading, setLoading } = useLoading();
    const [modalCreate, setModalVisibleCreate] = useState(false);
    const [changedList, setChangedList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [modalChoose, setModalVisibleChoose] = useState(false);
    const [recipeOptions, setRecipeOptions] = useState<RecipeOptions | null>(null);
    const [showDrill, setShowDrill] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await get(`recipe_list_ai?page=${currentPage}`);
                setData(response.data.results);
                setTotalPages(response.data.results.total_pages);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        fetchData();
    }, [currentPage, changedList]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString("pt-PT", {
            hour: "numeric",
            minute: "numeric",
        });
        return formattedDate;
    }

    const createRecipe = async () => {
        setLoading(true);
        try {
            const dataCreate = {
                title: title,
                desc: desc,
            }
            const response = await post(
                `/generate_recipe_ai/`,
                dataCreate
            );

            if (response.status === 200) {
                console.log(response);
                setRecipeOptions(response.data.message);
                setModalVisibleCreate(false);
                setTimeout(() => {
                    setModalVisibleChoose(true);
                }, 500);
                setLoading(false);
            } else {
                Toaster.show("Erro ao criar receita.", "error");
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            Toaster.show(
                "Erro ao criar receita. Tente novamente mais tarde.",
                "error"
            );
            setLoading(false);
        }
    };

    const create_recipe_ai = async (ingredients: string[], type: string) => {
        setLoading(true);
        try {
            const dataCreate = {
                title: title,
                desc: desc,
                ingr_list: ingredients,
                style: type
            }
            const response = await post(
                `/create_recipe_ai/`,
                dataCreate
            );

            if (response.status === 200) {
                Toaster.show("Receita Criada com sucesso.", "success");
                setModalVisibleChoose(false);
                console.log(response);
                setRecipeOptions(null);
                setTitle("");
                setDesc("");
                setChangedList(!changedList);
                setLoading(false);
            } else {
                Toaster.show("Erro ao criar receita.", "error");
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            Toaster.show(
                "Erro ao criar receita. Tente novamente mais tarde.",
                "error"
            );
            setLoading(false);
        }
    };

    const deleteRecipe = async (recipe_id: string) => {
        try {
            const response = await post(
                `/recipe_delete_ai/`,
                JSON.stringify({ recipe_id: recipe_id })
            );

            if (response.status === 200) {
                Toaster.show("Receita apagada com sucesso.", "success");
                setLoading(true);
                setChangedList(!changedList);
            } else {
                Toaster.show("Erro ao apagar receita.", "error");
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            Toaster.show(
                "Erro ao apagar receita. Tente novamente mais tarde.",
                "error"
            );
        }
    };


    const RecipeCard = ({ type, recipe, color }) => {
        const variants = {
            initial: { opacity: 0, y: 20 },
            animate: { opacity: 1, y: 0 }
        };

        return (
            <motion.div
                variants={variants}
                initial={loading ? false : "initial"}
                animate={loading ? false : "animate"}
                transition={{ duration: 0.5, delay: type === "Económica" ? 0.2 : type === "Clássica" ? 0.4 : 0.6 }}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col h-full"
            >
                <h3 style={{ color: color }} className={`text-xl font-semibold mb-2`}>{type}</h3>
                <p className="text-sm text-gray-600">
                    {recipe.descricao}
                </p>
                <div className="py-4 mt-auto">
                    <h4 className="font-medium pb-2">Ingredientes:</h4>
                    <ul className="list-disc list-inside text-sm text-gray-600">
                        {recipe.ingredientes.map((ingredient, index) => (
                            <li key={index}>
                                {ingredient}
                            </li>
                        ))}
                    </ul>
                </div>
                <Button
                    disabled={loading}
                    onClick={() => create_recipe_ai(recipe.ingredientes, type)}
                    style={{ backgroundColor: color }}
                    className={`mt-auto w-full p-2 rounded text-white transition-all duration-300 ease-in-out opacity-90 hover:opacity-100`}
                >
                    Escolher {type}
                </Button>
            </motion.div>
        );
    };

    const darkMode = useDarkMode();

    const renderTableDrill = (recipe) => {
        if (recipe) {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Instruçoes</TableHead>
                            <TableHead>Comentários</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>{recipe.recipe_intructions}</TableCell>
                            <TableCell>{recipe.recipe_comments}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            );
        } else {
            return null;
        }
    }
    return (
        <Page title={"Gerir Receitas"} loading={loading}>
            {tokenVerified ? (
                <div className="min-h-screen">
                    {data && (
                        <div className="w-[90%] m-auto text-black">
                            {/* Header Section */}
                            <div className="flex items-center justify-between py-4">
                                <CardHeader>
                                    <CardTitle className="text-4xl font-semibold text-blue-dark">
                                        Receitas
                                    </CardTitle>
                                    <CardDescription>
                                        Consulte e crie receitas com ajuda no nosso assistente AI.
                                    </CardDescription>
                                </CardHeader>
                                <Button onClick={() => {
                                    if (recipeOptions) {
                                        setModalVisibleChoose(true);
                                    } else {
                                        setModalVisibleCreate(true);
                                    }
                                }}>
                                    <IoAdd size={16} className="mr-2" /> Receita
                                </Button>
                            </div>


                            {/* Recipe Table */}
                            {data && data["recipes"].length > 0 ? (
                                <Table>
                                    <TableHeader className="bg-white">
                                        <TableRow>
                                            <TableHead>Nome</TableHead>
                                            <TableHead>Descrição</TableHead>
                                            <TableHead>Estado</TableHead>
                                            <TableHead>Date</TableHead>
                                            <TableHead></TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {data["recipes"].map((item, index) => (
                                            <React.Fragment key={`frag-${item.recipe_id}`}>
                                                <TableRow key={index} className="bg-white">
                                                    <TableCell>{item.recipe_name}</TableCell>
                                                    <TableCell>{item.recipe_descrip}</TableCell>
                                                    <TableCell
                                                        className={darkMode ? "text-white" : "text-black"}
                                                    >
                                                        {item.recipe_status}
                                                    </TableCell>
                                                    <TableCell
                                                        className={darkMode ? "text-white" : "text-black"}
                                                    >
                                                        {formatDate(item.ztimestamp)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className="flex space-x-4">
                                                            <FaTrash
                                                                onClick={() =>
                                                                    Toaster.showWithHandler(
                                                                        `Estará a eliminar o item ${item.recipe_name}. Deseja continuar?`,
                                                                        () => deleteRecipe(item.recipe_id),
                                                                        { duration: Infinity }
                                                                    )
                                                                }
                                                                title="Eliminar"
                                                                className="text-red-300 hover:text-red-500 text-xl cursor-pointer"
                                                            />
                                                            <FaSearch
                                                                className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                                                                title="Mais Informações"
                                                                onClick={() => {
                                                                    setShowDrill(showDrill === item ? null : item);
                                                                }}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {showDrill &&
                                                    <TableRow className='bg-amber-50 hover:bg-amber-50' key={`drillTable${item.recipe_id}`} style={{ display: showDrill === item ? 'table-row' : 'none' }}>
                                                        <TableCell colSpan={5}>
                                                            {renderTableDrill(showDrill)}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <div className="text-center text-2xl text-blue-dark h-screen">
                                    Não existem Receitas.
                                </div>
                            )}
                        </div>
                    )}

                    {/* Pagination */}
                    <div className="flex justify-center pb-8 pt-4">
                        <Paginate
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            loading={!data}
                        />
                    </div>

                    <Modal isOpen={modalCreate} setIsOpen={setModalVisibleCreate}>
                        <div>
                            <DialogTitle>Criar Receita</DialogTitle>
                            <InfoComponent>
                                <div>
                                    <p>
                                        Preencha os campos abaixo para criar uma nova
                                        receita.
                                    </p>
                                    <p>
                                        A partir do título e descrição, a nossa AI irá
                                        fornecer 3 opções de receitas das quais deverá escolher uma.
                                    </p>
                                </div>
                            </InfoComponent>
                            <div className="flex flex-col gap-4 px-2 py-4">
                                <div>
                                    <Label>Nome da Receita</Label>
                                    <Input
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Ex: Bolo de Chocolate"
                                    />
                                </div>
                                <div>
                                    <Label>Descrição da Receita</Label>
                                    <Input
                                        value={desc}
                                        onChange={(e) => setDesc(e.target.value)}
                                        placeholder="Ex: Bolo de Chocolate com cobertura de Morango"
                                    />
                                </div>
                                <div className="pt-5">
                                    <Button onClick={createRecipe}>Criar Receita</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal isOpen={modalChoose} setIsOpen={setModalVisibleChoose}>
                        <div >
                            <DialogTitle className="text-2xl mb-4">Opções de Receitas</DialogTitle>
                            <div className="grid grid-cols-3 gap-4">
                                {recipeOptions && (
                                    <>
                                        <RecipeCard
                                            type="Económica"
                                            recipe={recipeOptions.economica}
                                            color="#15803d"
                                        />
                                        <RecipeCard
                                            type="Clássica"
                                            recipe={recipeOptions.classica}
                                            color="#1d4ed8"
                                        />

                                        <RecipeCard
                                            type="Sofisticada"
                                            recipe={recipeOptions.sofisticada}
                                            color="#7e22ce"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="flex justify-end mt-6">
                                <Button
                                    disabled={loading}
                                    className="bg-red-300 hover:bg-red-400 transition-all duration-300 ease-in-out"
                                    onClick={() => { setModalVisibleChoose(false); setRecipeOptions(null); }}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <Loading isOpen={loading} />
                </div>
            ) : (
                // <Loading isOpen={true} />
                <></>
            )}
        </Page>
    );
};

export default RecipeAiPage;
