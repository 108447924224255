// React
import React, { useState, useEffect, useRef } from "react";

// contexts
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";

// components
import Page from "../../page";
import ChartComponent from "../../../../components/reusable/charts/chartComponent";
import MyTable from "../../../../components/reusable/table/table-component";
import SelectComponent from "../../../../components/reusable/select/select";
import NWeeksFormatter from "../../../../components/reusable/select/nweeks";
import KpiFormatter from "../../../../components/reusable/select/kpi";
import Loading from "../../../../components/reusable/loading/loading";
import CardComponent from "../../../../components/reusable/cardComponent";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import SearchBar from "../../../../components/ui/searchbar";
import { differenceInDays, format } from "date-fns";

import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";

type ChartOptions = Record<string, any>;

const CostByTypePage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "SHOPPING PAGE - PURCHASES RESALE",
  });

  const { get } = useApi();
  const { loading, setLoading } = useLoading();
  const [chartLoaded, setChartLoaded] = useState(false);
  const [nweeks, setNweeks] = useState<number>(4);
  const [kpi, setKpi] = useState("quantity");
  const chartRef = useRef(null);
  const [graphData, setGraphData] = useState<Record<string, ChartOptions>>({
    //q01: {},
    //q02: {},
    q52: {},
  });

  const [kpis, setKpis] = useState({
    absolute: [0, 0, 0, 0],
    variation: [0, 0, 0, 0],
  });
  const [lastDate, setLastDate] = useState(null);
  const [columns, setColumns] = useState([
    { headerName: "Data da Fatura de Compra", field: "purch_invoice_date" },
    { headerName: "Descrição do Produto", field: "product_description" },
    { headerName: "Quantidade Comprada", field: "purchase_quantity" },
    { headerName: "Preço Unitário de Compra (€)", field: "purchase_unit_pvp" },
    { headerName: "Último Preço de Venda (€)", field: "last_sale_price" },
    { headerName: "Data da Última Venda", field: "last_sale_date" },
    { headerName: "Quantidade Total Vendida", field: "total_quantity_sold" },
    { headerName: "Preço Máximo de Venda (€)", field: "max_sale_price" },
    { headerName: "Preço Mínimo de Venda (€)", field: "min_sale_price" },
    { headerName: "Preço Médio de Venda (€)", field: "avg_sale_price" },
    { headerName: "Média de Unidades Vendidas por Semana", field: "avg_units_sold_per_week" },
    { headerName: "Semanas para Vender Estoque", field: "weeks_to_sell" },
    { headerName: "Margem Bruta por Unidade (€)", field: "gross_margin_per_unit" },
    { headerName: "Percentagem de Margem Bruta (%)", field: "gross_margin_percentage" },

  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const darkMode = useDarkMode();

  function handleChartFinished() {
    if (chartRef.current) {
      chartRef.current.getEchartsInstance().resize();
    }
    setChartLoaded(true);
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy");
  };

  const getColorBasedOnDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const diffInDays = differenceInDays(today, date);

    const green = [0, 255, 0];
    const yellow = [255, 255, 0];
    const red = [255, 0, 0];

    let color;
    if (diffInDays <= 7) {
      color = green;
    } else if (diffInDays <= 14) {
      const ratio = (diffInDays - 7) / 7;
      color = yellow.map((value, index) => Math.round(value * ratio + green[index] * (1 - ratio)));
    } else {
      const ratio = (diffInDays - 14) / 14;
      color = red.map((value, index) => Math.round(value * ratio + yellow[index] * (1 - ratio)));
    }

    return `${color[0]}, ${color[1]}, ${color[2]}`;
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchGraphData = async () => {
      try {
        const promises = [
          //get("d20_despesas_last_date"),
          //get(`d20_q11_line_despesas/?nweeks=${nweeks}`),
          //get(`d20_pie_tt_purch/?nweeks=${nweeks}`),
          get(`d20_report_comp_purch_prod_revenda/?nweeks=${nweeks}`),
        ];
    
        const responses = await Promise.allSettled(promises);
    
        // Process last date even if others failed
        /*if (responses[0].status === "fulfilled") {
          setLastDate(responses[0].value.data);
          console.log("Last date data:", responses[0].value.data);
        } else {
          console.error("Failed to fetch last date", responses[0].reason);
        }*/
    
        setGraphData({
          //q01: responses[1].status === "fulfilled" ? responses[1].value.data : {},
          //q02: responses[2].status === "fulfilled" ? responses[2].value.data : {},
          q52: responses[0].status === "fulfilled" ? responses[0].value.data : {},
        });
    
        setLoading(false);
      } catch (error) {
        console.error("Error fetching graph data:", error);
        setLoading(false);
      }
    };
    

    const fetchKpiData = async () => {
      try {
        const response = await get(`get_purchases_kpi/?nweeks=${nweeks}`);
        setKpis(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    if (nweeks) {
      fetchGraphData();
      //fetchKpiData();
    }

    return () => {
      controller.abort();
    };
  }, [nweeks, kpi]);

  const kpiDescription = {
    quantity:
      "Items vendidos representa o número de produtos individuais vendidos pelo restaurante",
    total_sales:
      "Total de vendas representa o valor total de vendas gerado pelo restaurante",
    numero_faturas:
      "Número de faturas representa o número total de faturas emitidas pelo restaurante",
  };

  useEffect(() => {
    if (graphData.q52 && searchItem) {
      const filtered = graphData.q52?.filter((recipe) =>
        recipe.rest_prod_desc.toLowerCase().includes(searchItem.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [graphData.q52, searchItem]);
  return (
    <Page loading={loading}>
      {tokenVerified ? (
        <div className="h-screen">
          {!loading && (<div>
            <div className="flex flex-inline justify-between">
              <CardHeader>
                <CardTitle>Análise de Compras de Produtos para Revenda</CardTitle>
                <CardDescription>
                  Indicadores das Compras analisando as últimas {nweeks} semanas
                </CardDescription>
              </CardHeader>
              <div className="mt-5 mil:pt-0">
                <SelectComponent
                  formatter={NWeeksFormatter}
                  darkMode={darkMode}
                  onChange={setNweeks}
                />
              </div>
            </div>
            {/*
            {kpis && (
              <div className="mt-5">
                <div className="grid items-center gap-2 lg:grid-cols-2 xl:grid-cols-5">
                  <CardComponent
                    title={"Total de Vendas"}
                    value={`${kpis["absolute"][1]}€`}
                    variation={kpis["variation"][1]}
                  />
                  <CardComponent
                    title={"Nº Total de Faturas"}
                    value={`${kpis["absolute"][0]}`}
                    variation={kpis["variation"][0]}
                  />
                  <CardComponent
                    title={"Valor médio p/Fatura"}
                    value={`${kpis["absolute"][3]}€`}
                    variation={kpis["variation"][3]}
                  />
                  <CardComponent
                    title={"Itens vendidos"}
                    value={`${kpis["absolute"][2]}`}
                    variation={kpis["variation"][2]}
                  />
                  <CardComponent
                    title={"Últimos dados disponíveis"}
                    value={formatDate(lastDate)}
                    showVariation={false}
                    color={getColorBasedOnDate(lastDate)}

                  />
                </div>
              </div>
            )}
            */}

            <div className="padding">
              {/*
              <div className="grid xl:grid-cols-2 gap-4">
                <ChartComponent
                  chartRef={chartRef}
                  option={graphData.q01}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />

                <ChartComponent
                  chartRef={chartRef}
                  option={graphData.q02}
                  handleChartFinished={handleChartFinished}
                  ChangeScale={false}
                />
              </div>
              */}

              <div className="py-10 w-full">
                <div className="py-4">
                <SearchBar
                  search={search}
                  setSearch={setSearch}
                  setFetchSearch={setSearchItem}

                />
                </div>
                <div className="w-full">
                  <MyTable
                    rowData={
                      filteredData.length > 0 ? filteredData : graphData.q52
                    }
                    columnDefs={columns}
                    darkMode={darkMode}
                    title="Relatório de Análise de Compras de Produtos para Revenda"
                  />
                </div>
              </div>
            </div>
          </div>)}
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};
export default CostByTypePage;
