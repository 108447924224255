import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../../../../components/ui/accordion";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Card } from "../../../../components/ui/card";

export function Guide() {
  return (
    <Card className="p-6">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold text-blue-dark">
          Definição da Receita - Guia do Utilizador
        </CardTitle>
        <CardDescription>
          Bem-vindo à AI4Chef! Este guia ajudará a entender os conceitos
          principais envolvidos na criação de uma receita.
        </CardDescription>
      </CardHeader>

      <hr className="border-t border-_secondary my-8" />

      <CardDescription className="text-justify">
        Como exemplo, vamos criar uma receita de Pizza Quatro Estações
        utilizando a aplicação. Esta é uma receita que usa ingredientes
        pré-preparados como a massa da base ou o molho de tomate (que têm as
        suas próprias receitas, baseadas em{" "}
        <strong>ingredientes simples</strong>, isto é, comprados a um fornecedor
        externo e prontos a usar, muitas vezes produtos frescos como vegetais,
        legumes ou carne e peixe) e por isso é considerada uma{" "}
        <strong>receita complexa</strong> (no contexto da aplicação). Vamos
        seguir os passos essenciais para configurar estes ingredientes
        "especiais" necessários, definir a receita principal e validar a receita
        para que fique activa e os seus custos possam ser monitorizados
        diáriamente.
      </CardDescription>

      <Accordion type="multiple">
        <AccordionItem value="step-1">
          <AccordionTrigger>
            Passo 1: Criar as Receitas Simples
          </AccordionTrigger>
          <AccordionContent>
            <p>
              A Pizza Quatro Estações utiliza dois ingredientes que são
              preparados no restaurante: Massa de Pizza e Molho de Tomate. Estes
              devem ser configurados como receitas simples antes de criar a
              receita principal.
            </p>

            <Accordion type="single" collapsible className="ml-4">
              <AccordionItem value="step-1-1">
                <AccordionTrigger>
                  1. Criar a Receita de Massa de Pizza
                </AccordionTrigger>
                <AccordionContent>
                  <ol>
                    <li>
                      Aceda à secção <strong>Receitas</strong> no menu principal
                      e clique em <strong>Nova Receita</strong>.
                    </li>
                    <li>
                      Preencha as seguintes informações gerais:
                      <ul>
                        <li>
                          <strong>Nome</strong>: Massa de Pizza Individual
                        </li>
                        <li>
                          <strong>Descrição</strong>: Receita de massa ideal
                          para 10 pizzas individuais.
                        </li>
                        <li>
                          <strong>Número de Doses</strong>: 10
                        </li>
                      </ul>
                    </li>
                    <li>
                      Clique em <strong>Próximo</strong> para associar os
                      ingredientes. Seleccione os ingredientes simples e
                      especifique as quantidades:
                      <ul>
                        <li>Farinha de trigo: 1,5 kg</li>
                        <li>Água morna: 800 ml</li>
                        <li>Fermento biológico seco: 10 g</li>
                        <li>Sal: 30 g</li>
                        <li>Azeite de oliva: 50 ml</li>
                        <li>Açúcar: 10 g</li>
                      </ul>
                    </li>
                    <li>
                      Opcionalmente, insira informações sobre o preparo no campo
                      de descrição da receita.
                    </li>
                    <li>
                      Clique em <strong>Guardar</strong> para concluir.
                    </li>
                  </ol>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="step-1-2">
                <AccordionTrigger>
                  2. Criar a Receita de Molho de Tomate
                </AccordionTrigger>
                <AccordionContent>
                  <ol>
                    <li>
                      Clique em <strong>Nova Receita</strong> na secção{" "}
                      <strong>Receitas</strong>.
                    </li>
                    <li>
                      Preencha as informações gerais:
                      <ul>
                        <li>
                          <strong>Nome</strong>: Molho de Tomate
                        </li>
                        <li>
                          <strong>Descrição</strong>: Molho autêntico para
                          pizzas, rende 10 porções.
                        </li>
                        <li>
                          <strong>Número de Doses</strong>: 10
                        </li>
                      </ul>
                    </li>
                    <li>
                      Clique em <strong>Próximo</strong> e associe os
                      ingredientes simples com as quantidades:
                      <ul>
                        <li>Tomates pelados: 1 kg</li>
                        <li>Azeite de oliva: 30 ml</li>
                        <li>Alho: 2 dentes</li>
                        <li>Sal e pimenta: a gosto</li>
                        <li>Manjericão fresco: a gosto</li>
                      </ul>
                    </li>
                    <li>
                      Clique em <strong>Guardar</strong> para finalizar.
                    </li>
                  </ol>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="step-2">
          <AccordionTrigger>
            Passo 2: Criar os Ingredientes Processados
          </AccordionTrigger>
          <AccordionContent>
            <p>
              Para usar a Massa de Pizza e o Molho de Tomate na receita
              principal, crie ingredientes associados às receitas simples.
            </p>
            <Accordion type="single" collapsible className="ml-4">
              <AccordionItem value="step-2-1">
                <AccordionTrigger>
                  1. Criar o Ingrediente “Massa de Pizza”
                </AccordionTrigger>
                <AccordionContent>
                  <ol>
                    <li>
                      Aceda à secção <strong>Ingredientes</strong> e clique em{" "}
                      <strong>Novo Ingrediente</strong>.
                    </li>
                    <li>
                      Preencha as informações gerais:
                      <ul>
                        <li>
                          <strong>Nome</strong>: Massa de Pizza
                        </li>
                        <li>
                          <strong>Descrição</strong>: Massa preparada no
                          restaurante para pizzas individuais.
                        </li>
                        <li>
                          <strong>Tipo</strong>: Receita
                        </li>
                      </ul>
                    </li>
                    <li>
                      No campo <strong>Associar Receita</strong>, seleccione
                      “Massa de Pizza Individual”.
                    </li>
                    <li>
                      Clique em <strong>Guardar</strong>.
                    </li>
                  </ol>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="step-2-2">
                <AccordionTrigger>
                  2. Criar o Ingrediente “Molho de Tomate”
                </AccordionTrigger>
                <AccordionContent>
                  <ol>
                    <li>
                      Clique em <strong>Novo Ingrediente</strong>.
                    </li>
                    <li>
                      Preencha as informações gerais:
                      <ul>
                        <li>
                          <strong>Nome</strong>: Molho de Tomate
                        </li>
                        <li>
                          <strong>Descrição</strong>: Molho preparado com
                          tomates frescos ou pelados.
                        </li>
                        <li>
                          <strong>Tipo</strong>: Receita
                        </li>
                      </ul>
                    </li>
                    <li>
                      No campo <strong>Associar Receita</strong>, seleccione
                      “Molho de Tomate”.
                    </li>
                    <li>
                      Clique em <strong>Guardar</strong>.
                    </li>
                  </ol>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="step-3">
          <AccordionTrigger>
            Passo 3: Criar a Receita Complexa “Pizza Quatro Estações”
          </AccordionTrigger>
          <AccordionContent>
            <p>
              Agora que os ingredientes processados estão configurados, vamos
              criar a receita principal.
            </p>
            <ol>
              <li>
                Na secção <strong>Receitas</strong>, clique em{" "}
                <strong>Nova Receita</strong>.
              </li>
              <li>
                Preencha as informações gerais:
                <ul>
                  <li>
                    <strong>Nome</strong>: Pizza Quatro Estações
                  </li>
                  <li>
                    <strong>Descrição</strong>: Receita de pizza clássica
                    italiana, rende 10 porções.
                  </li>
                  <li>
                    <strong>Número de Doses</strong>: 10
                  </li>
                </ul>
              </li>
              <li>
                Clique em <strong>Próximo</strong> para associar os
                ingredientes. Escolha os seguintes ingredientes da lista e
                especifique as quantidades:
                <ul>
                  <li>Massa de Pizza: 10 unidades</li>
                  <li>Molho de Tomate: 10 porções</li>
                  <li>Mussarela: 1 kg</li>
                  <li>Presunto: 200 g</li>
                  <li>Cogumelos: 200 g</li>
                  <li>Alcachofras: 200 g</li>
                  <li>Azeitonas pretas: 50 unidades</li>
                  <li>Orégano: a gosto</li>
                  <li>Azeite de oliva: a gosto</li>
                </ul>
              </li>
              <li>
                Clique em <strong>Guardar</strong>.
              </li>
            </ol>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="step-4">
          <AccordionTrigger>Passo 4: Validar a Receita</AccordionTrigger>
          <AccordionContent>
            <ol>
              <li>
                Aceda à secção <strong>Gestão de Menu</strong> e localize a
                Pizza Quatro Estações.
              </li>
              <li>
                Clique em <strong>Validar Receita</strong>. A aplicação irá
                verificar automaticamente:
                <ul>
                  <li>
                    Se todos os ingredientes estão associados e configurados
                    corretamente.
                  </li>
                  <li>
                    Se os SKUs estão associados aos ingredientes simples para
                    cálculo de custos.
                  </li>
                </ul>
              </li>
              <li>
                Caso a validação seja bem-sucedida, o custo da receita será
                calculado diariamente. Se houver erros, corrija conforme as
                mensagens exibidas.
              </li>
            </ol>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="step-5">
          <AccordionTrigger>
            Passo 5: Monitorizar Custos e Ajustar
          </AccordionTrigger>
          <AccordionContent>
            <ol>
              <li>
                Acesse as páginas de análise na aplicação para monitorizar os
                custos da Pizza Quatro Estações.
              </li>
              <li>
                Utilize os insights fornecidos pela AI4Chef para ajustar preços
                e margens conforme necessário.
              </li>
            </ol>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <CardDescription className="mt-4">
        <strong>Parabéns!</strong> Você concluiu a configuração da Pizza Quatro
        Estações na aplicação AI4Chef. Continue a explorar as funcionalidades e
        aproveite o poder da Inteligência Artificial para otimizar o seu
        negócio!
      </CardDescription>
    </Card>
  );
}

export default Guide;
