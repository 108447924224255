// React
import React, { useState, useMemo } from "react";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useNavigate } from "react-router-dom";

// Components
import Page from "../../page";
import { Table, TableHeader, TableBody, TableRow, TableCell } from "../../../../components/ui/table";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
// Icons
import { FaSearch } from "react-icons/fa";



const QueriesPage = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const darkMode = useDarkMode();
  const navigate = useNavigate();

  useTokenVerifier({
    onTokenVerified: () => setTokenVerified(true),
    log: "QUERIES PAGE",
  });

  const columns = useMemo(
    () => [
      { headerName: "Consulta", field: "consulta" },
      { headerName: "Descrição", field: "descricao" },
      { headerName: "Consultar", field: "consultar" },
    ],
    []
  );

  const baseRows = useMemo(
    () => [
      {
        id: "1",
        consulta: "Consulta dos dados próprios do Cliente",
        descricao: "Consultar os dados do cliente",

      },
      {
        id: "2",
        consulta: "Consulta de Receitas",
        descricao: "Consultar as receitas do restaurante",

      },
      {
        id: "3",
        consulta: "Consulta ultimos valores de custo de Receitas",
        descricao: "Consultar os valores do custo das receitas",

      },
      {
        id: "4",
        consulta: "Consulta ultimos valores de custo de Ingredientes",
        descricao: "Consultar o valor do custo dos ingredientes",

      },
      {
        id: "5",
        consulta: "Consulta ultimos valores de custo de SKUs Activos",
        descricao: "Consultar o valor do custo dos SKUs ativos",

      },
      {
        id: "6",
        consulta: "Consulta ultimos valores de custo de SKUs Não Activos",
        descricao: "Consultar o valor do custo dos SKUs não ativos",

      },
      {
        id: "13",
        consulta: "Consulta de Preço de Ingredientes - Valores Diários",
        descricao: "Consultar os Preço de Ingredientes - Valores Diários",

      },
      {
        id: "17",
        consulta: "Consulta preços de SKUs",
        descricao:
          "Consultar o preço dos SKUs num determinado período de tempo",

      },
      {
        id: "18",
        consulta: "Consulta preços de ingredientes",
        descricao:
          "Consultar o preço dos ingredientes num determinado período de tempo",

      },
      {
        id: "19",
        consulta: "Consulta preços de receitas",
        descricao:
          "Consultar o preço dos receitas num determinado período de tempo",
      },
      {
        id: "23",
        consulta: "Consulta para detectar Ingredientes sem SKUs",
        descricao:
          "Consultar os ingredientes sem SKUs com preços na data actual",

      },
    ],
    []
  );



  return (
    <Page title="Consultas" loading={false}>
      {tokenVerified ? (
        <div className="min-h-screen h-full">
          <div className="pb-10">
            <CardHeader>
              <CardTitle>Consultas</CardTitle>
              <CardDescription>
                Selecione uma consulta da lista abaixo para visualizar os dados correspondentes.
              </CardDescription>
            </CardHeader>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: darkMode ? "#422653" : "",
                    color: darkMode ? "white" : "",
                  }}
                >
                  Identificador
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      backgroundColor: darkMode ? "#422653" : "",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {baseRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell key={rowIndex}>
                    {rowIndex + 1}
                  </TableCell>
                  {columns.map((column, colIndex) => (
                    <TableCell key={colIndex}>
                      {row[column.field]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <FaSearch
                      className="cursor-pointer"
                      title="Consultar"
                      onClick={() => navigate(`/app/consultas/consulta?id=${row.id}`, { state: { nConsulta: row.id } })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default QueriesPage;