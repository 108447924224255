// React
import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { Lock, User, LogIn, UserPlus } from "lucide-react";

import debounce from "lodash/debounce";
import ReCAPTCHA from "react-google-recaptcha";

// Utils
import axios from "../../utils/api/axios";
import useTokenVerifier from "../../utils/hooks/useTokenVerifier";
import { Input } from "../../components/ui/input";
import { useUser } from "../../utils/contexts/UserContext";
import useAuth from "../../utils/hooks/useAuth";
import Toaster from "../../components/reusable/Toaster";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const Auth: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null); // Ref for ReCAPTCHA component
  const navigate = useNavigate();
  const location = useLocation();
  const { user, fetchUser } = useUser();
  const { auth, setAuth } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [isLogin, setIsLogin] = useState(true);

  const from = location.state?.from?.pathname || "/app/homepage";

  useTokenVerifier({
    onTokenVerified: debounce(() => navigate(from), 500), // Debounce the navigation
    log: "LOGIN REFRESH",
  });

  const allowLogin = (): boolean => {
    if (!username) {
      Toaster.show("Introduza o seu username", "error");
      return false;
    }
    if (!password) {
      Toaster.show("Introduza a sua password", "error");
      return false;
    }
    return true;
  };

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    if (!allowLogin()) {
      return;
    }

    // Trigger the reCAPTCHA check
    if (recaptchaRef.current) {
      recaptchaRef.current.execute(); // This will trigger the reCAPTCHA check
    } else {
      setIsSubmitting(false);
    }
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "signup",
        new URLSearchParams({
          username: username,
          password1: password,
          password2: confirmPassword,
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setAuth({ accessToken: response.data.access });
      await fetchUser();
      navigate("/app/homepage"); // aqui talvez se deve redirecionar para a pagina de login até para pedir verificacao de email? e nesse caso nao guardar token nem refresh?
    } catch (err) {
      setError("Sign up failed. Please try again.");
      console.error("Sign up error", err);
    }
  };

  const handleRecaptchaChange = async (token: string | null) => {
    if (!token) {
      Toaster.show(
        "Erro ao verificar o reCAPTCHA. Por favor, tente novamente.",
        "error"
      );
      setIsSubmitting(false);
      return;
    }
    if (recaptchaToken === token) return; // Prevent redundant updates

    setRecaptchaToken(token);

    // Proceed with the login after token is set
    try {
      const response = await axios.post(
        "login",
        new URLSearchParams({
          username: username,
          password: password,
          recaptchaToken: token,
        }).toString(),
        {
          headers: {
            "X-CSRFToken": "",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      setAuth({ accessToken: response.data.access });
      await fetchUser();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        Toaster.show(err.response.data.message, "error");
      } else {
        Toaster.show(
          "Erro ao fazer login. Por favor tente novamente. Em caso de dúvidas contacte o suporte.",
          "error"
        );
      }
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };

  return (
    <div className="min-h-screen w-full bg-[#F5EAFB] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-md bg-white rounded-[20px] shadow-[0px_3px_8px_rgba(20,27,52,0.04)] p-8"
      >
        <div className="text-center mb-8">
          <div className="self-center bg-[#EAF3F4] shadow-[0px_4px_8px_rgba(20,27,52,0.04)] text-sm text-neutral-900 px-4 py-1 rounded-[20px] inline-block mb-4">
            {isLogin ? "Bem-vindo de volta!" : "Junte-se a nós hoje"}
          </div>
          <h1 className="text-4xl leading-none tracking-[-1.5px]">
            <span>{isLogin ? "Entrar no " : "Criar "}</span>
            {!isLogin && <span>conta </span>}
            <span className="text-[#9700CE]">AI4Chef</span>
          </h1>
          <p className="text-[#747474] text-base leading-[29px] opacity-80 mt-4">
            {isLogin
              ? "Aceda à sua conta e comece a gerir a sua cozinha de forma eficiente"
              : "Crie uma conta para começar a usar a IA para gerir a sua cozinha"}
          </p>
        </div>

        <form
          className="space-y-6"
          onSubmit={isLogin ? handleEmailSignIn : handleEmailSignUp}
        >
          <div className="space-y-2">
            <Label htmlFor="username">Utilizador</Label>
            <div className="relative">
              <Input
                id="username"
                type="text"
                placeholder="Introduza o seu utilizador"
                className="pl-10"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <User className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <div className="relative">
              <Input
                id="password"
                type="password"
                placeholder="Introduza a sua password"
                className="pl-10"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
            </div>
          </div>

          {!isLogin && (
            <div className="space-y-2">
              <Label htmlFor="confirmPassword">Confirmar Password</Label>
              <div className="relative">
                <Input
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirme a sua password"
                  className="pl-10"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
              </div>
            </div>
          )}

          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            size="invisible"
            onChange={handleRecaptchaChange}
          />

          <Button
            type="submit"
            className="w-full bg-[#9700CE] hover:bg-[#7a00a5] shadow-[0px_20px_35px_rgba(151,0,206,0.3)]"
            size="lg"
            disabled={isSubmitting}
          >
            {isLogin ? (
              <LogIn className="mr-2 h-4 w-4" />
            ) : (
              <UserPlus className="mr-2 h-4 w-4" />
            )}
            {isLogin ? "Entrar" : "Criar conta"}
          </Button>
        </form>

        <div className="mt-6 text-center">
          <p className="text-sm text-[#747474]">
            {isLogin ? "Não tem uma conta? " : "Já tem uma conta? "}
            <button
              onClick={() => setIsLogin(!isLogin)}
              className="text-[#9700CE] hover:underline font-medium"
            >
              {isLogin ? "Criar conta" : "Entrar"}
            </button>
          </p>
        </div>

        <button
          onClick={() => (window.location.href = "https://www.ai4chef.com")}
          className="mt-8 text-sm text-[#747474] hover:text-[#9700CE] text-center w-full"
        >
          ← Página inicial
        </button>
      </motion.div>
    </div>
  );
};

export default Auth;
