// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";

// Icons
import { IoAdd } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import { GrStatusDisabled, GrStatusGood } from "react-icons/gr";

import {
  CardDescription,
  CardTitle,
  CardHeader,
} from "../../../../components/ui/card";
import ProviderForm from "./ProviderFrom";
// Styles
// (No styles imported in this file)

const Providers = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();
  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "MENU INVOICES",
  });

  const { get, post } = useApi();
  const [providers, setProviders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const [editSupplier, setEditSupplier] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [filteredProviders, setFilteredProviders] = useState([]);

  const changeState = async (supplier_id: string, status: string) => {
    setLoading(true);
    const data = {
      suppl_id: supplier_id,
      status: status,
    };
    const response = await post(
      `/change_supplier_state/`,
      JSON.stringify(data)
    );

    if (response.status === 200) {
      Toaster.show("Estado alterado.", "success");
      setChange(!change);
    } else {
      Toaster.show("Erro ao alterar estado.", "error");
    }
  };

  const handleEdit = (supplier) => {
    setEditSupplier(supplier);
    setShowModal(true);
  };

  const toggleHandler = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    // Get providers from the backend
    const getProviders = async () => {
      setLoading(true);
      try {
        const response = await get("get_all_suppliers");
        console.log(response.data);
        setProviders(response.data);
        setFilteredProviders(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    getProviders();
  }, [change]);

  useEffect(() => {
    if (showAll) {
      setFilteredProviders(providers);
    } else {
      setFilteredProviders(
        providers.filter((provider) => provider.suppl_status === "active")
      );
    }
  }, [showAll, providers]);

  const darkMode = useDarkMode();

  return (
    <Page title={"Fornecedores"} loading={loading}>
      <div className="min-h-screen">
        <div className="w-[90%] m-auto text-black">
          {/* Header Section */}
          <div className="flex items-center justify-between">
            <CardHeader>
              <CardTitle className="text-4xl font-semibold text-blue-dark">
                Fornecedores
              </CardTitle>
              <CardDescription>
                Consulte e edite as suas fornecedores.
              </CardDescription>
            </CardHeader>
            <Button
              onClick={() => {
                setShowModal(true);
              }}
            >
              <IoAdd size={16} className="mr-2" /> Fornecedor
            </Button>
          </div>

          {/* Info Section */}
          <div className="flex flex-col">
            <div className="w-full">
              <InfoComponent text="">
                <div>
                  <p>Crie um fornecedor</p>
                </div>
              </InfoComponent>
            </div>
            <div className="flex items-center justify-end pb-2 gap-2 ">
              {/* <button
                                onClick={toggleHandler}
                                className={`relative inline-flex items-center px-1 w-12 h-6 rounded-full focus:outline-none transition-colors duration-200 ${!showAll ? 'bg-green-500' : 'bg-gray-300'
                                    }`}
                                role="switch"
                                aria-checked={showAll}
                            >
                                <span className="sr-only">Toggle</span>
                                <span
                                    className={`inline-block w-5 h-5 transform translate-x-0 rounded-full bg-white shadow-md transition-transform duration-200 ${!showAll ? 'translate-x-full' : 'translate-x-0'
                                        }`}
                                ></span>
                            </button> */}
              <div className="flex relative bg-zinc-200 w-fit rounded-full cursor-pointer">
                <div
                  className={`rounded-full px-4 py-2 w-60 flex justify-center h-9 `}
                  onClick={() => setShowAll(false)}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out  ${
                      !showAll ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    Fornecedores Ativos
                  </p>
                </div>
                <div
                  className={`rounded-full px-4 py-2 w-60 flex justify-center h-9 `}
                  onClick={() => setShowAll(true)}
                >
                  <p
                    className={`z-[2] text-sm transition-colors duration-500 ease-in-out ${
                      showAll ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    Todos os Fornecedores
                  </p>
                </div>
                <div
                  className={`absolute bg-gray-900 top-0 w-60 h-9 rounded-full z-[1] transition-all duration-500 ease-in-out ${
                    !showAll ? "translate-x-0" : "translate-x-[100%]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          {/* <div className="py-4">
                            <Input
                                type="text"
                                name="search"
                                placeholder="Pesquisar"
                                className="w-80 h-10"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div> */}

          {providers.length > 0 ? (
            <div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nome</TableHead>
                    <TableHead>Endereço</TableHead>
                    <TableHead>País</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Contato</TableHead>
                    <TableHead>NIPC</TableHead>
                    <TableHead>Opções</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody
                  style={{ backgroundColor: darkMode ? "#262853" : "" }}
                  className="bg-white divide-y divide-gray-200"
                >
                  {filteredProviders.map((provider) => (
                    <TableRow key={provider.suppl_id}>
                      <TableCell>{provider.suppl_name}</TableCell>
                      <TableCell>
                        {provider.suppl_address},{" "}
                        {provider.suppl_postal_code.join(", ")}
                      </TableCell>
                      <TableCell>{provider.suppl_countrycode}</TableCell>
                      <TableCell>{provider.suppl_maincontact_email}</TableCell>
                      <TableCell>
                        {provider.suppl_maincontact_name} -{" "}
                        {provider.suppl_maincontact_phone}
                      </TableCell>
                      <TableCell>{provider.suppl_nipc}</TableCell>
                      <TableCell className="flex items-center space-x-6">
                        {provider.suppl_status === "active" ? (
                          <GrStatusGood
                            title="Desativar"
                            className="text-green-300 hover:text-green-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                            onClick={() =>
                              Toaster.showWithHandler(
                                `Estará a desativar o fornecedor ${provider.suppl_name}. Deseja continuar?`,

                                () =>
                                  changeState(provider.suppl_id, "inactive"),
                                { duration: Infinity }
                              )
                            }
                          />
                        ) : (
                          <GrStatusDisabled
                            title="Ativar"
                            className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                            onClick={() =>
                              Toaster.showWithHandler(
                                `Estará a ativar o fornecedor ${provider.suppl_name}. Deseja continuar?`,
                                () => changeState(provider.suppl_id, "active"),
                                { duration: Infinity }
                              )
                            }
                          />
                        )}
                        <MdOutlineModeEditOutline
                          className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                          title="Editar"
                          onClick={() => {
                            handleEdit(provider);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div
              style={{ color: darkMode ? "white" : "black" }}
              className="text-center text-2xl mt-10"
            >
              Nao existem fornecedores criados. Crie um fornecedor.
            </div>
          )}
        </div>
        <Modal isOpen={showModal} setIsOpen={setShowModal}>
          <ProviderForm
            setShowModal={setShowModal}
            setChange={setChange}
            supplier={editSupplier}
          />
        </Modal>
        <Loading isOpen={loading} />
      </div>
    </Page>
  );
};

export default Providers;
