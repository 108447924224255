import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../../utils/api/axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../utils/contexts/UserContext"; // or wherever your user hook is
import { Check } from "lucide-react";
import { motion } from "framer-motion";

const PricingTable = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const { user, loading, setLoading } = useUser();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosPrivate.get("/get-stripe-products");
        const data = response.data;
        setProducts(data); // Assuming the response is an array of products
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    // Only fetch products if not loading and user is present
    if (!loading && user) {
      fetchProducts();
    }
  }, [loading, user]); // re-run if `loading` or `user` changes

  return (
    <div className="grid lg:grid-cols-2 sm:grid-cols-2 gap-6 max-sm:max-w-sm max-sm:mx-auto mt-12">
      {products.map((product, index) => (
        <motion.div
          key={product.id}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, margin: "-100px" }}
          transition={{ duration: 0.5, delay: index * 0.2 }}
          whileHover={{ y: -10 }}
          className={`flex flex-col p-6 bg-white rounded-[20px] border border-secondary shadow-[0px_3px_8px_rgba(234,204,245,0.5)] relative`}
        >
          <div className="text-center">
            <h3 className="text-xl font-bold text-neutral-900">
              {product.name}
            </h3>
            <div className="mt-4">
              <span className="text-4xl font-bold">{product.price}€</span>
              <span className="text-sm text-gray-600">/month</span>
            </div>
          </div>

          <motion.ul
            className="mt-6 space-y-4 flex-grow"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: index * 0.2 + 0.4 }}
          >
            {product.marketing_features.map((feature, i) => (
              <motion.li
                key={i}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 + 0.4 + i * 0.1 }}
                className="flex items-center gap-2"
              >
                <Check className="h-5 w-5 text-_primary" />
                <span className="text-sm text-[#747474]">{feature.name}</span>
              </motion.li>
            ))}
          </motion.ul>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => navigate(`/payment/${product.id}`)}
            className="mt-6 w-full py-3 px-4 rounded-xl font-medium bg-_primary text-white"
          >
            Começar
          </motion.button>
        </motion.div>
      ))}
    </div>
  );
};

export default PricingTable;
