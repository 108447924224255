// React
import React, { useEffect, useState, useCallback, useRef } from "react";

// contexts
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../Profile/usedarkmode";

// components
import Page from "../page";
import ChartFunc from "../../../components/reusable/charts/chartFunc";
import MyTable from "../../../components/reusable/table/table-component";
import SelectComponent from "../../../components/reusable/select/select";
import Loading from "../../../components/reusable/loading/loading";
import NWeeksFormatter from "../../../components/reusable/select/nweeks";
import ChartComponent from "../../../components/reusable/charts/chartComponent";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

// icons / images
import { FaChartBar, FaList, FaTable } from "react-icons/fa";
import { useLoading } from "../../../utils/contexts/LoadingContext";

const INITIAL_CHART_STATE = null;
const INITIAL_CHART_FUNC_STATE = {
  func: "",
  option: null,
  drilldown_data: [],
};
const INITIAL_COLUMNS = [
  { headerName: "Name", field: "name" },
  { headerName: "Category", field: "category" },
  { headerName: "Price", field: "price" },
  { headerName: "Variation", field: "variation" },
];

const General = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const [seeChart, setSeeChart] = useState(true);

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "GENERAL GRAPHS PAGE",
  });

  const darkMode = useDarkMode();
  const { get } = useApi();
  const [chartData, setChartData] = useState({
    q11: INITIAL_CHART_FUNC_STATE,
    q12: INITIAL_CHART_STATE,
    q21: INITIAL_CHART_STATE,
    q22: INITIAL_CHART_STATE,
    q31: INITIAL_CHART_STATE,
    q32: INITIAL_CHART_STATE,
  });
  const [columnsD1Q22] = useState(INITIAL_COLUMNS);
  const [nweeks, setNweeks] = useState(4);
  const { loading, setLoading } = useLoading();
  const chartRef = useRef();

  const fetchChartData = useCallback(async (endpoint, params = {}) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `dash1_v2_${endpoint}/${queryString ? `?${queryString}` : ""}`;

    try {
      setLoading(true);
      const response = await get(url);
      setChartData((prevData) => ({
        ...prevData,
        [endpoint]: response.data,
      }));
    } catch (error) {
      console.error(`Error fetching data for ${endpoint}:`, error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const endpoints = ["q11", "q12", "q21", "q22", "q31", "q32"];
    endpoints.forEach((endpoint) => fetchChartData(endpoint, { nweeks }));
  }, [nweeks, fetchChartData]);

  const renderChart = (ChartComponent, option, props = {}) => (
    <ChartComponent option={option} chartRef={chartRef} {...props} />
  );

  return (
    <Page loading={loading}>
      {tokenVerified ? (
        <div className="z-0">
          {!loading && (
            <div>
              <div className="flex flex-inline justify-between mb-5">
                <CardHeader>
                  <CardTitle>Visão Geral</CardTitle>
                  <CardDescription>
                    Acompanhe os preços de todos os produtos do mercado
                  </CardDescription>
                </CardHeader>
                <div className="mt-5 mil:pt-0">
                  <SelectComponent
                    formatter={NWeeksFormatter}
                    darkMode={darkMode}
                    onChange={setNweeks}
                  />
                </div>
              </div>
              <div className="grid xl:grid-cols-2 gap-4 ">
                {renderChart(ChartFunc, chartData.q11.option, {
                  func: chartData.q11.func,
                  drilldown_data: chartData.q11.drilldown_data,
                })}
                {renderChart(ChartComponent, chartData.q12)}
              </div>

              <CardHeader className="mt-10 mb-5">
                <CardTitle>Análise por Categoria</CardTitle>
                <CardDescription>
                  Variações de preço por categoria de produto
                </CardDescription>
              </CardHeader>
              <div className="grid xl:grid-cols-2 gap-4 ">
                {renderChart(ChartComponent, chartData.q31)}
                {renderChart(ChartComponent, chartData.q32)}
              </div>

              <div className="mt-10 mb-5 flex justify-between items-center">
                <CardHeader>
                  <CardTitle>Análise por Produto</CardTitle>
                  <CardDescription>
                    Saiba quais produtos mais variam de preço
                  </CardDescription>
                </CardHeader>
                <div className="flex gap-6 items-center">
                  <FaChartBar
                    className={`cursor-pointer transition-all duration-300 ease-in-out`}
                    color={seeChart ? "#484c9c" : "black"}
                    onClick={() => setSeeChart(true)}
                    size={36}
                    title="Ver Gráfico"
                  />
                  <FaTable
                    className={`cursor-pointer transition-all duration-300 ease-in-out`}
                    color={!seeChart ? "#484c9c" : "black"}
                    onClick={() => setSeeChart(false)}
                    size={34}
                    title="Ver Tabela"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 ">
                {seeChart ? (
                  renderChart(ChartComponent, chartData.q21)
                ) : (
                  <MyTable
                    rowData={chartData.q22}
                    columnDefs={columnsD1Q22}
                    title="Produtos com maior variação"
                    darkMode={darkMode}
                  />
                )}
              </div>
            </div>
          )}
          <Loading isOpen={loading} />
        </div>
      ) : (
        <></>
      )}
    </Page>
  );
};

export default General;
