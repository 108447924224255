import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../../../../components/ui/accordion";
import faqs from "../../../../assets/jsons/faqs.json";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Card } from "../../../../components/ui/card";
export function FAQ() {
  return (
    <Card className="p-6">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold text-blue-dark">
          Perguntas Frequentes (FAQ)
        </CardTitle>
        <CardDescription>
          Esperamos que este FAQ tenha ajudado a esclarecer as suas dúvidas.
          Caso precise de mais assistência, não hesite em contactar a nossa
          equipa de suporte!
        </CardDescription>
      </CardHeader>
      <hr className="border-t border-_secondary my-8" />
      <Accordion type="single" collapsible>
        {faqs.map((item, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger>{item.question}</AccordionTrigger>
            <AccordionContent>{item.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
}
