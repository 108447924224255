import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../../../../components/ui/accordion";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Card } from "../../../../components/ui/card";
import { MathJax, MathJaxContext } from "better-react-mathjax";
export function Glossary() {
  return (
    <Card className="p-6">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold text-blue-dark">
          Glossário
        </CardTitle>
        <CardDescription>
          Aqui encontra uma lista de termos técnicos utilizados no AI4CHEF.
        </CardDescription>
      </CardHeader>
      <hr className="border-t border-_secondary my-8" />
      <>
        <div className="bg-white rounded-lg mb-4">
          <CardTitle className="text-xl font-semibold text-blue-dark">
            Entrada no Glossário: Markup
          </CardTitle>
          <CardDescription>
            <strong>Definição: </strong>
            Markup é o valor adicionado ao preço de custo de um produto ou
            serviço para determinar o seu preço de venda. Geralmente, é expresso
            como uma percentagem do preço de custo. O markup assegura que uma
            empresa cobre os seus custos e gera lucro.
          </CardDescription>
          <Accordion type="single" collapsible>
            <AccordionItem value={`item-detail`}>
              <AccordionTrigger>Explicação Detalhada</AccordionTrigger>
              <AccordionContent>
                O markup é um conceito essencial nas estratégias de precificação
                e ajuda as empresas a alcançarem a rentabilidade. Representa a
                diferença entre o custo de produção ou aquisição de um produto e
                o seu preço de venda. O valor do markup compensa a empresa pelas
                suas despesas operacionais e inclui uma margem de lucro.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-calculo`}>
              <AccordionTrigger>
                Fatores a considerar para o cálculo de markup
              </AccordionTrigger>
              <AccordionContent>
                <ol>
                  <li>
                    <strong>Custo dos Bens Vendidos (COGS):</strong> Os custos
                    diretos incorridos para produzir ou adquirir o produto.
                  </li>
                  <li>
                    <strong>Despesas Operacionais:</strong> Custos relacionados
                    à gestão do negócio, como renda, utilidades e salários.
                  </li>
                  <li>
                    <strong>Margem de Lucro Desejada:</strong> O lucro que a
                    empresa pretende alcançar por venda.
                  </li>
                </ol>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-formula`}>
              <AccordionTrigger>Fórmula para o Markup</AccordionTrigger>
              <AccordionContent>
                <div>
                  <MathJaxContext>
                    <MathJax>
                      {`\\[ 
                                            \\text{Percentagem de Markup} = \\left( \\frac{\\text{Preço de Venda} - \\text{Preço de Custo}}{\\text{Preço de Custo}} \\right) \\times 100
                                            \\]`}
                    </MathJax>
                  </MathJaxContext>

                  <CardTitle className="text-md ">
                    Reorganizada para Encontrar o Preço de Venda
                  </CardTitle>
                  <MathJaxContext>
                    <MathJax>
                      {`\\[
                                                \\text{Preço de Venda} = \\text{Preço de Custo} \\times \\left(1 + \\frac{\\text{Percentagem de Markup}}{100}\\right)
                                                \\]`}
                    </MathJax>
                  </MathJaxContext>

                  <CardTitle className="text-md ">Exemplo</CardTitle>
                  <p>
                    Imagine que gere um café e vende muffins caseiros. Veja como
                    o markup funciona passo a passo:
                  </p>
                  <ol>
                    <li>
                      <p>
                        <strong>Calcular o Preço de Custo:</strong>
                      </p>
                      <ul>
                        <li>Ingredientes para um muffin: 1,50 €</li>
                        <li>Embalagem por muffin: 0,20 €</li>
                        <li>Preço de Custo Total por muffin: 1,70 €</li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <strong>Decidir a Percentagem de Markup:</strong>
                      </p>
                      <ul>
                        <li>
                          Decide aplicar um markup de 50% para cobrir despesas
                          operacionais e gerar lucro.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <strong>Calcular o Preço de Venda:</strong>
                        <MathJaxContext>
                          <MathJax>
                            {`\\[
                                                        \\text{Preço de Venda} = \\text{Preço de Custo} \\times \\left(1 + \\frac{\\text{Percentagem de Markup}}{100}\\right)
                                                        \\]`}
                          </MathJax>
                          <MathJax>
                            {`\\[
                                                            \\text{Substituindo os valores:}
                                                            \\]`}
                          </MathJax>
                          <MathJax>
                            {`\\[
                                                                    \\text{Preço de Venda} = 1,70 \\times \\left(1 + \\frac{50}{100}\\right) = 1,70 \\times 1,5 = 2,55
                                                                    \\]`}
                          </MathJax>
                        </MathJaxContext>
                      </p>
                      <ul>
                        <li>Preço de Venda: 2,55 € por muffin</li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        <strong>Verificar o Valor do Markup:</strong>
                      </p>
                      <ul>
                        <li>
                          Valor do Markup = Preço de Venda - Preço de Custo =
                          2,55 € - 1,70 € = 0,85 €
                        </li>
                        <li>
                          Percentagem de Markup = (0,85 € / 1,70 €) × 100 = 50%
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-importancia`}>
              <AccordionTrigger>Importância</AccordionTrigger>
              <AccordionContent>
                <ul>
                  <li>
                    Garante a rentabilidade: Ajuda a cobrir custos e a gerar
                    lucro.
                  </li>
                  <li>
                    Simplifica a precificação (atribuição de preços): Método
                    fácil para calcular preços com margens de lucro
                    consistentes.
                  </li>
                  <li>
                    Flexível para estratégias: Permite ajustes com base nas
                    condições de mercado e na concorrência.
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-wrong-conceptions`}>
              <AccordionTrigger>Conceções Erradas Comuns</AccordionTrigger>
              <AccordionContent>
                <ol>
                  <li>
                    <strong>Markup vs. Margem:</strong> O markup baseia-se no
                    preço de custo, enquanto a margem baseia-se no preço de
                    venda. Por exemplo, um markup de 50% não significa uma
                    margem de lucro de 50%.
                  </li>
                  <li>
                    <strong>Custos Fixos vs. Variáveis:</strong> O markup é
                    calculado com base no custo direto (COGS), mas as empresas
                    também devem considerar custos fixos como renda e salários
                    ao definir os preços gerais.
                  </li>
                </ol>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-key-points`}>
              <AccordionTrigger>Pontos-Chave</AccordionTrigger>
              <AccordionContent>
                <ul>
                  <li>
                    O markup é uma ferramenta de precificação simples para
                    alcançar a rentabilidade.
                  </li>
                  <li>Calculado como uma percentagem do preço de custo.</li>
                  <li>
                    Ajuda a garantir que os custos sejam cobertos ao mesmo tempo
                    que se obtém lucro.
                  </li>
                </ul>
                <p>
                  Ao compreender e aplicar o conceito de markup, as empresas
                  podem definir preços de forma estratégica e manter a saúde
                  financeira.
                </p>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        <hr className="border-t border-_secondary my-8" />

        <div className="bg-white rounded-lg mb-4">
          <CardTitle className="text-xl font-semibold text-blue-dark">
            Entradada Glossário: Margem Bruta
          </CardTitle>
          <CardDescription>
            <strong>Definição:</strong> A Margem Bruta é uma métrica financeira
            simples, mas essencial, que mostra quanto dinheiro um restaurante
            mantém de cada venda depois de cobrir o custo dos ingredientes
            utilizados para preparar a comida. Este indicador mostra a
            rentabilidade dos itens do menu antes de considerar outras despesas,
            como renda, salários da equipa ou contas de eletricidade.
          </CardDescription>
          <Accordion type="single" collapsible>
            <AccordionItem value={`item-importancia-margem`}>
              <AccordionTrigger>Por que é Importante?</AccordionTrigger>
              <AccordionContent>
                <p>
                  Compreender a Margem Bruta ajuda os gestores de restaurantes
                  a:
                </p>
                <ul>
                  <li>
                    Identificar quais itens do menu são mais ou menos rentáveis.
                  </li>
                  <li>Tomar decisões mais acertadas sobre os preços.</li>
                  <li>
                    Controlar os custos dos ingredientes para melhorar a
                    rentabilidade global.
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-calcule-margem`}>
              <AccordionTrigger>Como é Calculada?</AccordionTrigger>
              <AccordionContent>
                <p>
                  Compreender a Margem Bruta ajuda os gestores de restaurantes
                  a:
                </p>
                <ul>
                  <li>
                    Identificar quais itens do menu são mais ou menos rentáveis.
                  </li>
                  <li>Tomar decisões mais acertadas sobre os preços.</li>
                  <li>
                    Controlar os custos dos ingredientes para melhorar a
                    rentabilidade global.
                  </li>
                </ul>
                <MathJaxContext>
                  <MathJax>
                    {`\\[
                                        \\text{Margem Bruta} = \\frac{\\text{Receita de Vendas} - \\text{Custo das Mercadorias Vendidas (CMV)}}{\\text{Receita de Vendas}} \\times 100
                                        \\]`}
                  </MathJax>
                </MathJaxContext>

                <CardTitle className="text-md ">Onde é usada</CardTitle>
                <ul>
                  <li>
                    <strong>Receita de Vendas</strong> é o preço cobrado aos
                    clientes por um prato.
                  </li>
                  <li>
                    <strong>CMV (Custo das Mercadorias Vendidas)</strong> é o
                    custo dos ingredientes utilizados para preparar esse prato.
                  </li>
                </ul>
                <p>
                  O resultado é uma percentagem que indica quanto do preço de
                  venda sobra como lucro após o pagamento dos ingredientes.
                </p>

                <CardTitle className="text-md ">Exemplo</CardTitle>
                <p>
                  Imagine que o seu restaurante vende uma{" "}
                  <strong>sanduíche de frango grelhado</strong> por{" "}
                  <strong>15€</strong> e os ingredientes (pão, frango, alface,
                  molho) custam <strong>6€</strong>.
                </p>

                <strong>Calcular o Lucro Bruto:</strong>
                <MathJaxContext>
                  <MathJax>
                    {`\\[
                                        \\text{Lucro Bruto} = \\text{Receita de Vendas} - \\text{CMV} = 15 - 6 = 9
                                        \\]`}
                  </MathJax>
                </MathJaxContext>

                <strong>Calcular a Margem Bruta:</strong>
                <MathJaxContext>
                  <MathJax>
                    {`\\[
                                        \\text{Margem Bruta} = \\frac{\\text{Lucro Bruto}}{\\text{Receita de Vendas}} \\times 100 = \\frac{9}{15} \\times 100 = 60\\%
                                        \\]`}
                  </MathJax>
                </MathJaxContext>

                <p>
                  <strong>Interpretação:</strong> Isto significa que 60% do
                  preço da sanduíche é lucro depois de pagar os ingredientes,
                  enquanto os 40% restantes vão para cobrir o custo dos
                  ingredientes.
                </p>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`item-usage-margem`}>
              <AccordionTrigger>Outros Conceitos de Margem</AccordionTrigger>
              <AccordionContent>
                <p>
                  Embora a Margem Bruta seja o conceito mais relevante para
                  analisar ingredientes e preços do menu, há outras margens que
                  podem ser úteis à medida que as operações do restaurante se
                  tornam mais complexas
                </p>
                <strong>Margem de Lucro Líquida:</strong>
                <ul>
                  <li>
                    Reflete a rentabilidade total após deduzir todas as despesas
                    operacionais (ex.: salários, renda, utilitários, impostos).
                  </li>
                  <li>
                    Exemplo: Se o restaurante gera 50.000€ em vendas e tem
                    custos totais (ingredientes + despesas) de 45.000€, a margem
                    de lucro líquida será:
                    <MathJaxContext>
                      <MathJax>
                        {`\\[
                                        \\text{Margem Líquida} = \\frac{\\text{Lucro Líquido}}{\\text{Receita de Vendas}} \\times 100 = \\frac{5000}{50000} \\times 100 = 10\\%
                                        \\]`}
                      </MathJax>
                    </MathJaxContext>
                  </li>
                </ul>

                <ul>
                  <li>
                    Reflete a rentabilidade total após deduzir todas as despesas
                    operacionais (ex.: salários, renda, utilitários, impostos).
                  </li>
                  <li>
                    Exemplo: Se o restaurante gera 50.000€ em vendas e tem
                    custos totais (ingredientes + despesas) de 45.000€, a margem
                    de lucro líquida será:
                    <MathJaxContext>
                      <MathJax>
                        {`\\[
                                        \\text{Margem Líquida} = \\frac{\\text{Lucro Líquido}}{\\text{Receita de Vendas}} \\times 100 = \\frac{5000}{50000} \\times 100 = 10\\%
                                        \\]`}
                      </MathJax>
                    </MathJaxContext>
                  </li>
                </ul>

                <strong>Margem Operacional:</strong>

                <ul>
                  <li>
                    Mede o lucro após custos operacionais, mas antes de impostos
                    e juros. Útil para avaliar a eficiência das operações.
                  </li>
                </ul>

                <strong>Margem de Contribuição (por item):</strong>

                <ul>
                  <li>
                    Mede quanto dinheiro sobra, após pagar pelos ingredientes
                    (CMV), para cobrir os custos fixos.
                  </li>
                  <li>
                    Ajuda a analisar a rentabilidade de itens específicos do
                    menu.
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value={`conclusions`}>
              <AccordionTrigger>
                Conclusões para o seu Restaurante
              </AccordionTrigger>
              <AccordionContent>
                <ul>
                  <li>
                    Monitore regularmente as Margens Brutas para garantir que os
                    itens do menu estão corretamente precificados e os custos
                    dos ingredientes controlados.
                  </li>
                  <li>
                    Promova pratos de alta margem em campanhas de marketing ou
                    como sugestões especiais.
                  </li>
                  <li>
                    Embora a Margem Bruta não inclua todas as despesas, é o
                    primeiro passo para construir um restaurante sustentável e
                    rentável.
                  </li>
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </>
    </Card>
  );
}
