// React
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Hooks
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";
// Components
import Page from "../../page";
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table";


// Icons
import { FaPlusCircle, FaSearch, FaTrash } from "react-icons/fa";
import { IoAdd } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";
import Modal from "../../../../components/reusable/Modal";
import {
    CardDescription,
    CardTitle,
    CardHeader,
} from "../../../../components/ui/card";
import InvoiceForm from "./InvoiceForm";
import React from "react";

interface ProductForm {
    id: number;
    productName: string;
    productQuantity: number;
    productPrice: number;
    productTax: number;
    priceIncludesTax?: boolean;
    categoryId?: string;
    category?: string;
    region?: string;
}

interface InvoiceFormProps {
    invoiceNumberProp?: string;
    invoiceDateProp?: string;
    providerNameProp?: string;
    totalAmountProp?: number;
    products?: ProductForm[];
}

interface PurchaseLineItem {
    purch_id: number;
    rest_site_id: number;
    purch_invoice_no: string;
    purch_invoice_date: string;
    purch_provider_id: number;
    purch_provider_desc: string;
    purch_account_type: string;
    purch_account_type_id: string;
    purch_total_amount: number;
    purch_linenumber: number;
    purch_item_desc: string;
    purch_quantity: number;
    purch_unitprice: number;
    purch_prod_taxrate: number;
    purch_line_amount: number;
    zdata: string;
    purch_unitprice_taxinc: number;
    purch_invoice_id: string | null;
}

interface GroupedInvoices {
    [key: string]: PurchaseLineItem[];
}

const Invoices = () => {
    const [tokenVerified, setTokenVerified] = useState(false);
    const navigate = useNavigate();
    // Callback function to update token verification status
    const handleTokenVerified = () => {
        setTokenVerified(true);
    };
    useTokenVerifier({
        onTokenVerified: handleTokenVerified,
        log: "MENU INVOICES",
    });

    const { get, post } = useApi();
    const [invoices, setInvoices] = useState<GroupedInvoices>(null);
    const [showModal, setShowModal] = useState(false);
    const { loading, setLoading } = useLoading();
    const [change, setChange] = useState(false);
    const [search, setSearch] = useState("");
    const [editInvoiceId, setEditInvoiceId] = useState(null);
    const [editableInvoice, setEditableInvoice] = useState<InvoiceFormProps>();
    const [showDrill, setShowDrill] = useState("");


    const deleteItem = async (purch_invoice_id: string) => {
        setLoading(true);
        const data = {
            purch_invoice_id: purch_invoice_id,
        };
        const response = await post(
            `/delete_invoice/`,
            JSON.stringify(data)
        );

        if (response.status === 200) {
            Toaster.show("Item apagado com sucesso.", "success");
            setChange(!change);
        } else {
            Toaster.show("Erro ao apagar item.", "error");
        }
    };


    useEffect(() => {
        if (editInvoiceId) {
            // Find the invoice with matching ID
            const invoiceToEdit = invoices[editInvoiceId];
    
    
            if (invoiceToEdit) {
                // Format data according to InvoiceFormProps interface
                const formattedInvoice: InvoiceFormProps = {
                    invoiceNumberProp: invoiceToEdit[0].purch_invoice_no,
                    invoiceDateProp: invoiceToEdit[0].purch_invoice_date,
                    providerNameProp: invoiceToEdit[0].purch_provider_desc,
                    totalAmountProp: invoiceToEdit[0].purch_total_amount,
                    products: invoiceToEdit.map((invoice) => ({
                        id: invoice.purch_id,
                        productName: invoice.purch_item_desc,
                        productQuantity: invoice.purch_quantity,
                        productPrice: invoice.purch_unitprice,
                        productTax: invoice.purch_prod_taxrate,
                        categoryId: invoice.purch_account_type_id,
                        category: ""
                    }))
                };
    
                setEditableInvoice(formattedInvoice);
                setShowModal(true);
            }
        }
    }, [editInvoiceId, invoices]);


    useEffect(() => {

        // Get providers from the backend
        const getInvoices = async () => {
            setLoading(true);
            try {
                const response = await get("get_all_invoices");
                setInvoices(response.data);
                setLoading(false);

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        getInvoices();

    }, [change]);



    const darkMode = useDarkMode();

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const renderTableDrill = (drillToDisplay) => {
        if (drillToDisplay) {
            return (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Descrição do Item</TableHead>
                            <TableHead>Quantidade</TableHead>
                            <TableHead>Categoria de Compra</TableHead>
                            <TableHead>Preço Unitário</TableHead>
                            <TableHead>Taxa de Imposto</TableHead>
                            <TableHead>Valor da Linha</TableHead>
                            <TableHead>Preço Unitário com Imposto</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {invoices[drillToDisplay].map((item, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell>{item.purch_item_desc}</TableCell>
                                <TableCell>{item.purch_quantity}</TableCell>
                                <TableCell>{item.purch_account_type}</TableCell>
                                <TableCell>{item.purch_unitprice}</TableCell>
                                <TableCell>{item.purch_prod_taxrate}%</TableCell>
                                <TableCell>{item.purch_line_amount ? item.purch_line_amount.toFixed(2) : 'N/A'}</TableCell>
                                <TableCell>{item.purch_unitprice_taxinc}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        } else {
            return null;
        }
    }


    return (
        <Page title={"Faturas"} loading={loading}>
            <div className="min-h-screen">
                <div className="w-[90%] m-auto text-black">
                    {/* Header Section */}
                    <div className="flex items-center justify-between">
                        <CardHeader>
                            <CardTitle className="text-4xl font-semibold text-blue-dark">
                                Faturas
                            </CardTitle>
                            <CardDescription>
                                Consulte e edite as suas faturas.
                            </CardDescription>
                        </CardHeader>
                        <Button
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            <IoAdd size={16} className="mr-2" /> Fatura
                        </Button>
                    </div>

                    {/* Info Section */}
                    <InfoComponent text="">
                        <div>
                            <p>
                                Crie um fatura
                            </p>
                        </div>
                    </InfoComponent>
                    {/* <div className="py-4">
                        <Input
                            type="text"
                            name="search"
                            placeholder="Pesquisar"
                            className="w-80 h-10"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div> */}

                    {invoices ? (
                        <div>
                            <Table >
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Nome do Fornecedor</TableHead>
                                        <TableHead>Data da Fatura</TableHead>
                                        <TableHead>Número da Fatura</TableHead>
                                        {/* <TableHead>Categoria de Compra</TableHead> */}
                                        <TableHead>Valor Total</TableHead>
                                        {/* +++ <TableHead>Número da Linha</TableHead> */}
                                        {/* +++ <TableHead>Código do Item</TableHead> */}
                                        {/* <TableHead>Descrição do Item</TableHead>
                                        <TableHead>Quantidade</TableHead>
                                        <TableHead>Preço Unitário</TableHead>
                                        <TableHead>Taxa de Imposto</TableHead>
                                        <TableHead>Valor da Linha</TableHead>
                                        <TableHead>Preço Unitário com Imposto</TableHead> */}
                                        <TableHead>Opções</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody
                                    style={{ backgroundColor: darkMode ? "#262853" : "" }}
                                    className="bg-white divide-y divide-gray-200"
                                >
                                    {Object.entries(invoices).map(([invoiceId, invoiceGroup]) =>
                                         // Get first invoice from group
                                        (() => {
                                            const invoice = invoiceGroup[0];
                                            return (
                                                <React.Fragment key={`frag-${invoiceId}`}>
                                                    <TableRow key={invoice.purch_id}>
                                                        <TableCell>{invoice.purch_provider_desc || 'N/A'}</TableCell>
                                                        <TableCell>{formatDate(invoice.purch_invoice_date)}</TableCell>
                                                        <TableCell>{invoice.purch_invoice_no || 'N/A'}</TableCell>
                                                        <TableCell>{invoice.purch_total_amount}</TableCell>
                                                        <TableCell className="flex items-center space-x-6">
                                                            <FaTrash
                                                                title="Eliminar"
                                                                className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                                                                onClick={() =>
                                                                    Toaster.showWithHandler(
                                                                        `Estará a eliminar o item ${invoice.purch_invoice_no}. Deseja continuar?`,
                                                                        () => deleteItem(invoice.purch_invoice_id),
                                                                        { duration: Infinity }
                                                                    )
                                                                }
                                                            />
                                                            <MdOutlineModeEditOutline
                                                                className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                                                                title="Editar"
                                                                onClick={() => {
                                                                    setEditInvoiceId(invoice.purch_invoice_id);
                                                                }}
                                                            />
                                                            <FaSearch
                                                                className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                                                                title="Consultar"
                                                                onClick={() => {
                                                                    setShowDrill(showDrill === invoiceId ? "" : invoiceId);
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    {showDrill &&
                                                        <TableRow className='bg-amber-50 hover:bg-amber-50' key={`drillTable${invoiceId}`} style={{ display: showDrill === invoiceId ? 'table-row' : 'none' }}>
                                                            <TableCell colSpan={5}>
                                                                {renderTableDrill(showDrill)}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </React.Fragment>
                                            );
                                        })()
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    ) : (
                        <div
                            style={{ color: darkMode ? "white" : "black" }}
                            className="text-center text-2xl mt-10"
                        >
                            Sem faturas. Crie uma fatura.
                        </div>
                    )}
                </div>
                <Modal isOpen={showModal} setIsOpen={setShowModal}>
                    <InvoiceForm
                        setShowModal={setShowModal}
                        setChange={setChange}
                        setEditInvoiceId={setEditInvoiceId}
                        productsProp={editableInvoice?.products}
                        invoiceNumberProp={editableInvoice?.invoiceNumberProp}
                        invoiceDateProp={editableInvoice?.invoiceDateProp}
                        providerNameProp={editableInvoice?.providerNameProp}
                        totalAmountProp={editableInvoice?.totalAmountProp}
                        editPurchInvoiceId={editInvoiceId}
                    />
                </Modal>
                <Loading isOpen={loading} />
            </div>
        </Page>
    );
};

export default Invoices;

// {invoices.map((invoice) => (
//     <TableRow key={invoice.purch_id}>
//         <TableCell>{invoice.purch_provider_desc || 'N/A'}</TableCell>
//         <TableCell>{formatDate(invoice.purch_invoice_date)}</TableCell>
//         <TableCell>{invoice.purch_invoice_no || 'N/A'}</TableCell>
//         <TableCell>{invoice.purch_account_type_id}</TableCell>
//         <TableCell>{invoice.purch_total_amount}</TableCell>
//         {/* <TableCell>{invoice.purch_linenumber}</TableCell> */}
//         {/* <TableCell>{invoice.purch_item_code}</TableCell> */}
//         <TableCell>{invoice.purch_item_desc}</TableCell>
//         <TableCell>{invoice.purch_quantity}</TableCell>
//         <TableCell>{invoice.purch_unitprice}</TableCell>
//         <TableCell>{invoice.purch_prod_taxrate}%</TableCell>
//         <TableCell>{invoice.purch_line_amount ? invoice.purch_line_amount.toFixed(2) : 'N/A'}</TableCell>
//         <TableCell>{invoice.purch_unitprice_taxinc}</TableCell>
//         <TableCell className="flex items-center space-x-6">
//             <FaTrash
//                 title="Eliminar"
//                 className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
//                 onClick={() =>
//                     Toaster.showWithHandler(
//                         `Estará a eliminar o item ${invoice.purch_invoice_no}. Deseja continuar?`,
//                         () => deleteItem(invoice.purch_id),
//                         { duration: Infinity }
//                     )
//                 }
//             />
//             <MdOutlineModeEditOutline
//                 className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
//                 title="Editar"
//                 onClick={() => {
//                     setEditInvoiceId(invoice.purch_invoice_id);
//                 }}
//             />
//         </TableCell>
//     </TableRow>
// ))}