import { useEffect, useMemo, useRef, useState } from "react";
import Tesseract from 'tesseract.js';
import { DecimalInput, Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import {
    CardDescription,
    CardTitle,
    CardHeader,
} from "../../../../components/ui/card";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../../components/ui/accordion";
import SearchBar from "../../../../components/ui/searchbar";
import InfoComponent from "../../../../components/reusable/info/info.component";
import SelectComponent from "../../../../components/reusable/select/select";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import Toaster from "../../../../components/reusable/Toaster";
import { useNavigate } from "react-router-dom";
import { DialogTitle } from "../../../../components/ui/dialog";
import Paginate from "../../../../components/reusable/pagination/paginate";
import "../../../../assets/css/customScroll.styles.css";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table";
import { IoIosSearch } from "react-icons/io";
interface InvoiceFormProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setChange: React.Dispatch<React.SetStateAction<boolean>>;
    setEditInvoiceId: React.Dispatch<React.SetStateAction<string | null>>;
    invoiceNumberProp?: string;
    invoiceDateProp?: string;
    providerNameProp?: string;
    totalAmountProp?: number;
    productsProp?: ProductForm[];
    editPurchInvoiceId?: string;
}

interface ProductForm {
    id: number;
    productName: string;
    productQuantity: number;
    productPrice: number;
    productTax: number;
    priceIncludesTax?: boolean;
    ingred_id?: string;
    categoryId?: string;
    category?: string;
    region?: string;
    rest_prod_id?: string;
}



const regionFormatter = {
    getAll: () => ["Continente", "Açores", "Madeira"],
    format: (value: string) => value,
    default: "Continente",
};

const taxFormatterCont = {
    getAll: () => [6, 13, 23],
    format: (value: number) => `${value}%`,
    default: 0,
};

const taxFormatterAzores = {
    getAll: () => [4, 9, 16],
    format: (value: number) => `${value}%`,
    default: 0,
};

const taxFormatterMadeira = {
    getAll: () => [5, 12, 22],
    format: (value: number) => `${value}%`,
    default: 0,
};



export default function InvoiceForm(
    { setShowModal,
        setChange,
        setEditInvoiceId,
        editPurchInvoiceId = null,
        invoiceDateProp = "",
        invoiceNumberProp = "",
        totalAmountProp = 0,
        providerNameProp = "",
        productsProp = []
    }: InvoiceFormProps) {

    const { setLoading } = useLoading();
    const { get, post } = useApi();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [providers, setProviders] = useState([]);
    const purchaseTypeFormatter = useMemo(() => ({
        getAll: () => categories.map(category => category.purch_account_type),
        format: (value: string) => value,
        default: "Categoria",
    }), [categories]);

    const providerFormatter = useMemo(() => ({
        getAll: () => providers.map(provider => provider.suppl_name),
        format: (value: string) => value,
        default: "Fornecedor",
    }), [providers]);

    const [invoiceNumber, setInvoiceNumber] = useState(invoiceNumberProp);
    const [invoiceDate, setInvoiceDate] = useState(invoiceDateProp);
    const [providerName, setProviderName] = useState(providerNameProp);
    const [totalAmount, setTotalAmount] = useState(totalAmountProp);
    const [ingreds, setIngreds] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [totalPagesProd, setTotalPagesProd] = useState(1);
    const [searchProd, setSearchProd] = useState("");
    const [currentPageProd, setCurrentPageProd] = useState(1);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [image, setImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [products, setProducts] = useState<ProductForm[]>(productsProp); // State for product forms
    const [prods, setProds] = useState([]);

    const idCounter = useRef<number>(Date.now());

    const addProductForm = () => {
        setProducts([
            ...products,
            {
                id: idCounter.current++,
                productName: "",
                productQuantity: 0,
                productPrice: 0,
                productTax: 23,
                category: "",
                categoryId: "",
                region: "Continente",
            },
        ]);
    };

    const handleDeleteProduct = (id: number) => {
        setProducts(products.filter(product => product.id !== id));
    };
    const handleProductChange = (id: number, field: keyof ProductForm, value: any) => {
        const updatedProducts = products.map(product =>
            product.id === id ? { ...product, [field]: value } : product
        );
        setProducts(updatedProducts);
    };

    const handleProductChange2Fields = (id: number, updates: Partial<ProductForm>) => {
        const updatedProducts = products.map(product =>
            product.id === id ? { ...product, ...updates } : product
        );
        setProducts(updatedProducts);
    };

    // Function to handle image selection
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            console.log("File:", file);
            reader.onload = (e) => {
                setImage(e.target?.result as string);
                processImage(e.target?.result as string); // Start OCR processing
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to perform OCR and populate fields
    const processImage = async (imageData: string) => {
        try {
            const { data: { text } } = await Tesseract.recognize(imageData, 'eng');
            console.log("Extracted Text:", text); // Log the extracted text for debugging

            // Implement logic to parse extracted text and set form fields
            extractAndSetFields(text);

        } catch (error) {
            console.error("OCR Error:", error);
        }
    };

    // Function to extract data from OCR text and set form fields
    const extractAndSetFields = (text: string) => {
        // Example extractions (you'll need to customize these based on invoice formats):
        const invoiceNumberMatch = text.match(/Invoice Number:? (\w+)/i);
        if (invoiceNumberMatch) setInvoiceNumber(invoiceNumberMatch[1]);

        const invoiceDateMatch = text.match(/Invoice Date:? (\d{4}-\d{2}-\d{2})/i);
        if (invoiceDateMatch) setInvoiceDate(invoiceDateMatch[1]);

        // ... (Add more extraction logic for other fields)

        // Example for total amount (adjust regex as needed)
        const totalAmountMatch = text.match(/Total:? \$?([\d,.]+)/i);
        if (totalAmountMatch) setTotalAmount(parseFloat(totalAmountMatch[1].replace(/,/g, '')));
    };

    // Example usage within the component
    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        const selectedProvider = providers.find(prov => prov.suppl_name === providerName);
        const provId = selectedProvider ? selectedProvider.suppl_id : null;

        // Filter out products with default values

        const validProducts = products.filter(product =>
            product.productName !== "" &&
            product.productQuantity !== 0 &&
            product.productPrice !== 0 &&
            product.categoryId !== ""
        );

        if (validProducts.length === 0) {
            Toaster.show("Por favor, adicione pelo menos um produto e preencha os campos.", "error");
            return;
        }

        if (validProducts.length < products.length) {
            Toaster.show("Alguns produtos não foram preenchidos e serão ignorados.", "warning");
        }

        setLoading(true);

        const data = {
            invoiceNumber,
            invoiceDate,
            provId,
            providerName,
            totalAmount,
            validProducts,
            ...(editPurchInvoiceId && { purch_invoice_id: editPurchInvoiceId })
        };

        try {
            const url = editPurchInvoiceId ? "/update_invoice/" : "/create_invoice/";
            await post(url, data);
            setChange(prev => !prev);
            setEditInvoiceId(null);
            setShowModal(false);
            setLoading(false);
            Toaster.show(
                editPurchInvoiceId
                    ? "Fatura atualizada com sucesso."
                    : "Fatura criada com sucesso.",
                "success"
            );
        } catch (error) {
            console.error("Error saving invoice:", error);
            Toaster.show(
                editPurchInvoiceId
                    ? "Ocorreu um erro ao atualizar a fatura. Por favor, tente novamente."
                    : "Ocorreu um erro ao criar a fatura. Por favor, tente novamente.",
                "error"
            );
            setLoading(false);
        }
    };

    const calculateTotal = (product) => {
        const price = product.priceIncludesTax
            ? product.productPrice
            : product.productPrice * (1 + product.productTax / 100);
        return Number((price * product.productQuantity).toFixed(2));
    };

    const getTaxFormatter = (product: ProductForm) => {
        switch (product.region) {
            case "Açores":
                return taxFormatterAzores;
            case "Madeira":
                return taxFormatterMadeira;
            default:
                return taxFormatterCont;
        }
    };



    useEffect(() => {

        // Get categories from the backend
        const getCategories = async () => {
            setLoading(true);
            try {
                const response = await get("get_all_purch_account_types");
                setCategories(response.data);
                setLoading(false);

                if (response.data.length === 0) {
                    Toaster.showWithHandler(
                        "Nao existem categorias de compra. Deseja adicionar uma nova categoria?",
                        async () => {
                            navigate("/app/profile", { state: { tab: "restaurant" } });
                        },
                        { duration: Infinity }
                    );
                }

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        getCategories();

    }, []);

    useEffect(() => {

        // Get providers from the backend
        const getProviders = async () => {
            setLoading(true);
            try {
                const response = await get("get_all_suppliers");
                setProviders(response.data);
                setLoading(false);
                if (response.data.length === 0) {
                    Toaster.showWithHandler(
                        "Nao existem fornecedores. Deseja criar um novo fornecedor?",
                        async () => {
                            navigate("/app/providers");
                        },
                        { duration: Infinity }
                    );
                }

            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        getProviders();

    }, []);


    useEffect(() => {

        const getGraph = async (endpoint, params = {}) => {
            setLoading(true);
            const url = params
                ? `${endpoint}/?${Object.entries(params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")}`
                : `${endpoint}`;
            try {
                const response = await get(url);
                setIngreds(response.data.results.data);
                setTotalPages(response.data.results.total_pages);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        getGraph("ingred_list", {
            search: searchItem,
            page: currentPage,
        });

    }, [searchItem, currentPage]);


    useEffect(() => {
        console.log("Search Prod:", searchProd);
        const getProds = async (endpoint, params = {}) => {
            setLoading(true);
            const url = params
                ? `${endpoint}/?${Object.entries(params)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")}`
                : `${endpoint}`;
            try {
                const response = await get(url);
                setProds(response.data.results.data);
                setTotalPagesProd(response.data.results.total_pages);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error);
            }
        };
        getProds("get_prods", {
            search: searchProd,
            page: currentPageProd,
        });

    }, [searchProd, currentPageProd]);



    /* numero da fatura(optional)
    data da fatura
    id do fornecedor (select)
    tipo de compra (select)
    valor total

    produtos
    -numero da linha (posto automatico)
    -categoria ingrediente
        -codigo do item
        -descricao do item
    -quantidade
    -preco unitario
    -taxa de imposto do produto
    -valor da linha (calculado)
    
    */

    return (
        <div className="space-y-6">
            <div>
                <DialogTitle>
                    <CardHeader>
                        <CardTitle className="text-4xl font-semibold text-blue-dark">
                            Adicionar Fatura
                        </CardTitle>
                        <CardDescription>
                            Adicione faturas e produtos correspondentes.
                        </CardDescription>
                    </CardHeader>
                </DialogTitle>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Número da fatura (opcional)
                </Label>
                <div className="mt-2">
                    <Input
                        id="invoiceNumber"
                        name="invoiceNumber"
                        type="text"
                        placeholder="Digite o número da fatura"
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Data da fatura
                </Label>
                <div className="mt-2">
                    <Input
                        id="invoiceDate"
                        name="invoiceDate"
                        type="date"
                        placeholder="Selecione a data da fatura"
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex gap-6">
                <div>
                    <Label className="block text-gray-700 text-sm font-bold mb-1">
                        ID do fornecedor
                    </Label>
                    <div className="mt-2">
                        {/* Select Fornecedores */}
                        <SelectComponent
                            formatter={providerFormatter}
                            darkMode={false}
                            onChange={setProviderName}
                            value={providerName}
                        />
                    </div>
                </div>

            </div>
            <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Valor total
                </Label>
                <div className="mt-2">
                    <DecimalInput
                        id="totalAmount"
                        name="totalAmount"
                        placeholder="Digite o valor total"
                        value={totalAmount}
                        onChange={(e) => {
                            setTotalAmount(Number(e.target.value));
                        }}
                    />
                </div>
            </div>

            {/* <div>
                <Label className="block text-gray-700 text-sm font-bold mb-1">
                    Adicionar Imagem
                </Label>
                <div className="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                            <Label className="block text-gray-700 text-sm font-bold mb-1 cursor-pointer">
                                <span>Upload a file</span>
                                <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={handleImageChange}
                                    ref={fileInputRef}
                                />                            </Label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                    </div>
                </div>
            </div> */}
            <div className="flex justify-end">
                <Button type="button" onClick={addProductForm}>Add Product</Button>
            </div>
            <Accordion type="single" collapsible className="w-full mt-16">
                {products.map((product, index) => (
                    <AccordionItem key={product.id} value={`item-${index}`}>
                        <AccordionTrigger>Product {index + 1}</AccordionTrigger>
                        <AccordionContent className="space-y-6 px-2">
                            <div className="flex justify-end">
                                <Button className="bg-red-300 hover:bg-red-400 transition-all duration-300" type="button" onClick={() => handleDeleteProduct(product.id)}>Delete</Button>
                            </div>
                            <div>
                                <Label>Product Name</Label>
                                <Input
                                    placeholder="Digite o nome do produto"
                                    type="text"
                                    value={product.productName}
                                    onChange={(e) => handleProductChange(product.id, 'productName', e.target.value)}
                                    disabled={product.category === "Produtos para Revenda" || product.category === "Ingredientes"}
                                />
                            </div>
                            <div>
                                <Label className="block text-gray-700 text-sm font-bold mb-1">
                                    Tipo da Compra
                                </Label>
                                <div className="mt-2 flex flex-col gap-4">
                                    {/* Select Categoria de compras */}
                                    <SelectComponent
                                        formatter={purchaseTypeFormatter}
                                        darkMode={false}
                                        onChange={(value: string) => {
                                            const selectedCategory = categories.find(cat => cat.purch_account_type === value);
                                            handleProductChange2Fields(product.id, { category: value, categoryId: selectedCategory ? selectedCategory.purch_account_type_id : '' });
                                        }}
                                        value={product.category}
                                    />
                                    {product.category === "Ingredientes" && (
                                        <div className="max-h-72 scroll2 px-1" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                            <div>
                                                <DialogTitle >Associação de Ingredientes</DialogTitle>
                                                <InfoComponent text="">
                                                    <p>
                                                        Pesquise e associe ingredientes à sua fatura.
                                                    </p>
                                                </InfoComponent>

                                                <div className="mt-4 mb-1">
                                                    <SearchBar
                                                        search={search}
                                                        setSearch={setSearch}
                                                        setFetchSearch={setSearchItem}
                                                    />
                                                </div>
                                                {ingreds.length > 0 && (
                                                    <Table className="border border-gray-200 rounded-lg">
                                                        <TableHeader>
                                                            <TableRow>
                                                                <TableHead>Nome</TableHead>
                                                                <TableHead>Classe</TableHead>
                                                                <TableHead></TableHead>
                                                            </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                            {ingreds.map((ingredient) => (
                                                                <TableRow key={ingredient.ingr_id}>
                                                                    <TableCell>{ingredient.ingr_name}</TableCell>
                                                                    <TableCell>{ingredient.ingr_category}</TableCell>
                                                                    <TableCell>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={product?.ingred_id === ingredient.ingr_id}
                                                                            onChange={() => handleProductChange2Fields(product.id, { ingred_id: ingredient.ingr_id, productName: ingredient.ingr_name })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                )}
                                                <div className="flex justify-center">
                                                    <Paginate
                                                        currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}
                                                        totalPages={totalPages}
                                                        loading={!ingreds}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {product.category === "Produtos para Revenda" && (
                                        <div className="max-h-72 scroll2 px-1" style={{ overflowY: "auto", overflowX: "hidden" }}>
                                            <div>
                                                <DialogTitle >Associação de Produtos</DialogTitle>
                                                <InfoComponent text="">
                                                    <p>
                                                        Pesquise e associe produtos para revenda à sua fatura.
                                                    </p>
                                                </InfoComponent>

                                                <div className="mt-4 mb-1">
                                                    <SearchBar
                                                        search={search}
                                                        setSearch={setSearch}
                                                        setFetchSearch={setSearchProd}
                                                    />
                                                </div>
                                                {prods.length > 0 && (
                                                    <Table className="border border-gray-200 rounded-lg">
                                                        <TableHeader>
                                                            <TableRow>
                                                                <TableHead>Nome</TableHead>
                                                                <TableHead>Categoria</TableHead>
                                                                <TableHead></TableHead>
                                                            </TableRow>
                                                        </TableHeader>
                                                        <TableBody>
                                                            {prods.map((prod) => (
                                                                <TableRow key={prod.rest_prod_id}>
                                                                    <TableCell>{prod.rest_prod_desc}</TableCell>
                                                                    <TableCell>{prod.prod_category}</TableCell>
                                                                    <TableCell>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={product?.rest_prod_id === prod.rest_prod_id}
                                                                            onChange={() => handleProductChange2Fields(product.id, { rest_prod_id: prod.rest_prod_id, productName: prod.rest_prod_desc })}
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                )}
                                                <div className="flex justify-center">
                                                    <Paginate
                                                        currentPage={currentPageProd}
                                                        setCurrentPage={setCurrentPageProd}
                                                        totalPages={totalPagesProd}
                                                        loading={!prods}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div>
                                <Label>Quantidade</Label>
                                <DecimalInput
                                    decimalPlaces={3}
                                    placeholder="Digite a quantidade do produto"
                                    value={product.productQuantity !== null ? product.productQuantity : ''}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        handleProductChange(product.id, 'productQuantity', Number(value));
                                    }}
                                    inputMode="decimal"
                                />
                            </div>
                            <div>
                                <Label>Preço Unitário</Label>
                                <DecimalInput
                                    placeholder="Digite o preço unitário"
                                    value={product.productPrice !== null ? product.productPrice : ''}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        handleProductChange(product.id, 'productPrice', Number(value));
                                    }}
                                    inputMode="decimal"
                                />

                            </div>
                            <div className="flex gap-6">
                                <div>
                                    <Label>Regiao</Label>
                                    <SelectComponent
                                        formatter={regionFormatter}
                                        darkMode={false}
                                        onChange={(value: string) => {
                                            handleProductChange(product.id, 'region', value);
                                        }}
                                        value={product.region}
                                    />
                                </div>
                                <div>
                                    <Label>Taxa de IVA</Label>
                                    <SelectComponent
                                        formatter={getTaxFormatter(product)}
                                        darkMode={false}
                                        onChange={(value: number) => handleProductChange(product.id, 'productTax', value)}
                                        value={product.productTax}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <Label>Preço Inclui Taxa?</Label>
                                <input

                                    className="mb-1"
                                    type="checkbox"
                                    checked={product.priceIncludesTax}
                                    onChange={(e) => handleProductChange(product.id, 'priceIncludesTax', e.target.checked)}
                                />
                            </div>
                            <div>
                                <Label>Total</Label>
                                <Input
                                    type="number"
                                    value={calculateTotal(product)}
                                    disabled
                                />
                            </div>
                            {/* Add more product fields as needed */}
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
            <div className="flex justify-end">
                <Button onClick={handleSave}>Guardar</Button>
            </div>

        </div>
    );
}