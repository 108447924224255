import { axiosPrivate } from "../../../utils/api/axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../utils/contexts/UserContext"; // or wherever your user hook is
import { LogOut } from "lucide-react";
import { Button } from "../../../components/ui/button";
import PricingTable from "../../../components/reusable/PricingTable";

interface PricingTableProps {
  allowLogout?: boolean;
}

const PricingTablePage = ({ allowLogout = false }: PricingTableProps) => {
  const { setLoading } = useUser();

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axiosPrivate.post("logout", {
        headers: { "Content-Type": "application/json" },
      });
      sessionStorage.clear();
      window.location.href = "/";
      setLoading(false);
    } catch (error) {
      console.error("Logout failed", error);
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-light">
      <div className="font-[sans-serif]">
        <div className="max-w-5xl max-lg:max-w-3xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-2 text-gray-800">
              Escolha o seu plano e melhore a sua cozinha!
            </h2>
            <p className="text-sm text-gray-500">
              Veja a nossa oferta de planos e escolha o que melhor atende às
              suas necessidades
            </p>
          </div>
          <PricingTable />
        </div>
      </div>
      {allowLogout && (
        <div className="mt-8 px-2 py-2">
          <Button
            className="w-full bg-gray-200 hover:bg-red-400 border border-gray-400 text-black hover:text-white hover:border-red-400"
            onClick={handleLogout}
          >
            <LogOut className="mr-2 h-4 w-4" />
            Terminar Sessão
          </Button>
        </div>
      )}
    </div>
  );
};

export default PricingTablePage;
