// React
import { useEffect, useState } from "react";

// Hooks
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import { useClass } from "../../../../utils/contexts/ClassContext";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import SelectComponent from "../../../../components/reusable/select/select";
import ClasseFormatter from "../../../../components/reusable/select/classes";
import RecipeStatus from "../../../../components/reusable/select/recipeStatus";
import RecipeUsage from "../../../../components/reusable/select/recipeUsage";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import { Label } from "../../../../components/ui/label";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";

const recipeTypeFormatter = {
  getAll: () => ["Receita Simples", "Receita Complexa"],
  format: (value) => (value),
  getFullString(code: boolean): string {
    if (code) {
      return "Receita Simples";
    }
    else return "Receita Complexa";

  },

  default: "Receita Simples",
};

const RecipeForm = ({ recipe_id = 0, modalCloser, change, setChange, recipe = null }) => {
  const { Classes } = useClass();

  const { post, getCache, setCache, clearCache } = useApi();
  const [recipeName, setRecipeName] = useState("");
  const [recipeDescription, setRecipeDescription] = useState("");
  const [recipeClass, setRecipeClass] = useState("");
  const [recipeIntructions, setRecipeIntructions] = useState("");
  const [recipeComments, setRecipeComments] = useState("");
  const [recipeRecursive, setRecipeRecursive] = useState("Receita Simples");
  const [numberDoses, setNumberDoses] = useState(1);
  const [order, setOrder] = useState(0);
  const [recipeStatus, setRecipeStatus] = useState("");
  const [recipeUsage, setRecipeUsage] = useState("Receitas Internas(I)");
  const { setLoading } = useLoading();

  useEffect(() => {
    if (Classes.length > 0) {
      setRecipeClass(Classes[0].prod_category);
    }
  }, [Classes]);



  useEffect(() => {
    const cacheKey = recipe_id !== 0 ? `recipeEdit_${recipe_id}` : `recipeCreate_${recipe_id}`;
    const cachedData = getCache(cacheKey)
    if (recipe) {
      // If no cached data, use recipe data and cache it
      setRecipeName(cachedData ? cachedData.recipe_name : recipe.recipe_name);
      setRecipeDescription(cachedData ? cachedData.recipe_descrip : recipe.recipe_descrip);
      setRecipeClass(cachedData ? cachedData.recipe_class : recipe.recipe_class);
      setRecipeIntructions(cachedData ? cachedData.recipe_intructions : recipe.recipe_intructions);
      setRecipeComments(cachedData ? cachedData.recipe_comments : recipe.recipe_comments);
      setRecipeRecursive(cachedData ? recipeTypeFormatter.getFullString(cachedData.recipe_recursive) : recipeTypeFormatter.getFullString(recipe.recipe_recursive));
      setNumberDoses(cachedData ? cachedData.recipe_num_doses : recipe.recipe_num_doses);
      setOrder(cachedData ? cachedData.recipe_order : recipe.recipe_order);
      setRecipeStatus(cachedData ? cachedData.recipe_status : recipe.recipe_status);
      setRecipeUsage(cachedData ? RecipeUsage.getFullString(cachedData.recipe_usage) : RecipeUsage.getFullString(recipe.recipe_usage));
    } else {
      if (cachedData) {
        setRecipeName(cachedData.recipe_name || "");
        setRecipeDescription(cachedData.recipe_descrip ||  "");
        setRecipeClass(cachedData.recipe_class ||  "");
        setRecipeIntructions(cachedData.recipe_intructions ||  "");
        setRecipeComments(cachedData.recipe_comments ||  "");
        setRecipeRecursive(recipeTypeFormatter.getFullString(cachedData.recipe_recursive) ||  "Receita Simples");
        setNumberDoses(cachedData.recipe_num_doses ||  1);
        setOrder(cachedData.recipe_order ||  0);
        setRecipeStatus(cachedData.recipe_status ||  "");
        setRecipeUsage(RecipeUsage.getFullString(cachedData.recipe_usage) ||  "Receitas Internas(I)");
      }
    }
  }, [recipe, recipe_id]); // Added recipe_id to dependencies

  const updateCache = (updates: Partial<any>) => {
    const cacheKey = recipe_id !== 0 ? `recipeEdit_${recipe_id}` : `recipeCreate_${recipe_id}`;
    const currentCache = getCache(cacheKey) || {};
    setCache(cacheKey, { ...currentCache, ...updates });
  };

  const handleRecipeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipeName(e.target.value);
    updateCache({ recipe_name: e.target.value });
  };

  const handleRecipeDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRecipeDescription(e.target.value);
    updateCache({ recipe_descrip: e.target.value });
  };

  const handleRecipeClassChange = (value: string) => {
    setRecipeClass(value);
    updateCache({ recipe_class: value });
  };

  const handleRecipeInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRecipeIntructions(e.target.value);
    updateCache({ recipe_intructions: e.target.value });
  };

  const handleRecipeCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRecipeComments(e.target.value);
    updateCache({ recipe_comments: e.target.value });
  };

  const handleRecipeRecursiveChange = (value: string) => {
    setRecipeRecursive(value);
    updateCache({ recipe_recursive: value === "Receita Simples" ? false : true });
  };

  const handleNumberDosesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setNumberDoses(value);
    updateCache({ recipe_num_doses: value });
  };

  const handleRecipeStatusChange = (value: string) => {
    setRecipeStatus(value);
    updateCache({ recipe_status: value });
  };

  const handleRecipeUsageChange = (value: string) => {
    setRecipeUsage(value);
    updateCache({ recipe_usage: value.match(/\(([A-Z])\)/)?.[1] || "I" });
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      recipeName === "" ||
      recipeClass === "" ||
      recipeDescription === "" ||
      recipeRecursive === ""
    ) {
      modalCloser(false);
      if (isNaN(numberDoses)) {
        Toaster.show("Número de doses deve ser um número.", "error");
        return;
      }
      Toaster.show(
        "Por favor, preencha os campos de nome, doses, classe, descrição e tipo.",
        "error"
      );
      return;
    }
    if (numberDoses < 1) {
      Toaster.show("Número de doses deve ser igual ou superior a 1.", "error");
      return;
    }
    setLoading(true);

    const cacheKey = recipe_id !== 0 ? `recipeEdit_${recipe_id}` : `recipeCreate_${recipe_id}`;
    clearCache(cacheKey);

    const data = {
      recipe_id: recipe_id,
      recipe_name: recipeName,
      recipe_descrip: recipeDescription,
      recipe_class: recipeClass,
      recipe_intructions: recipeIntructions,
      recipe_comments: recipeComments,
      recipe_recursive: recipeRecursive === "Receita Simples" ? false : true,
      recipe_num_doses: numberDoses,
      recipe_order: recipe_id === 0 ? -1 : recipeRecursive === "s" ? 0 : order,
      recipe_status: recipe_id === 0 ? "INCOMPLETE" : recipeStatus,
      recipe_usage: recipeUsage.match(/\(([A-Z])\)/)?.[1] || "I",
      //ao criar uma nova receita, o order é -1 e ao editar uma receita, passar para simples order fica 0 e para complexa fica o valor do order
    };
    try {
      const response = await post(
        `/recipe_create_or_update/`,
        JSON.stringify(data)
      );

      if (response.status === 200) {
        Toaster.show("Receita guardada com sucesso.", "success");
        setChange(!change);
        modalCloser(false)
      } else {
        Toaster.show("Erro ao guardar receita.", "error");
        setLoading(false);
        modalCloser(false)
      }
    } catch (error) {
      Toaster.show("Erro ao guardar receita.", "error");
      setLoading(false);
    }
  };
  const darkMode = useDarkMode();

  return (
    <div className="max-h-[50vh]">
      <div className="flex items-center justify-center ">
        <div >
          <div className="mb-4 flex items-center">
            <div className="w-1/2 pr-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Nome
              </Label>
              <Input
                name="Nome da Receita"
                type="text"
                value={recipeName}
                onChange={handleRecipeNameChange}
              />
            </div>
            <div className="w-1/2 pl-2">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Classe
              </Label>
              <SelectComponent
                formatter={ClasseFormatter}
                darkMode={darkMode}
                onChange={handleRecipeClassChange}
                value={recipeClass}
              />
            </div>
          </div>

          <InfoComponent text="">
            <p>
              Defina a quantidade de porções ou refeições que a receita pode
              produzir.
            </p>
          </InfoComponent>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Número de Doses
            </Label>
            <Input
              name="Número de Doses"
              type="number"
              min={1}
              value={numberDoses}
              onChange={handleNumberDosesChange}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Descrição
            </Label>
            <Textarea
              value={recipeDescription}
              onChange={handleRecipeDescriptionChange}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Instruções
            </Label>
            <Textarea
              value={recipeIntructions}
              onChange={handleRecipeInstructionsChange}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Comentário
            </Label>
            <Textarea
              value={recipeComments}
              onChange={handleRecipeCommentsChange}
            />
          </div>

          {recipe_id !== 0 && (
            <div className="mb-4">
              <Label
                style={{ color: darkMode ? "white" : "black" }}
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Estado
              </Label>
              <SelectComponent
                formatter={RecipeStatus}
                darkMode={darkMode}
                onChange={handleRecipeStatusChange}
                value={recipeStatus}
              />
            </div>
          )}



          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Utilização da Receita
            </Label>
            <InfoComponent text="">
              <div className="flex flex-col gap-2">
                <p>
                  <strong>Receitas Menu(M)</strong> - Produtos vendidos pelo restaurante
                  confeccionados internamente.
                </p>
                <p>
                  <strong>Receitas Internas(I)</strong> - Componentes ou ingredientes que sejam
                  usados em receitas (um molho bechamel, por exemplo) ou que sejam
                  usados em porções como parte de um prato desde que sejam
                  confeccionados internamente e portanto tenham uma receita (mas
                  que não sejam vendidos directamente mas sim incorporados nos
                  produtos vendidos pelo restaurante).
                </p>
                <p>
                  <strong>Outras Receitas(O)</strong> - Produtos que o Restaurante tem interesse em
                  monitorizar o respectivo custo por diversas razões (pratos que
                  podem vir a fazer parte do menu ou já fizeram e podem voltar a
                  fazer, etc.)
                </p>
              </div>
            </InfoComponent>
            <SelectComponent
              formatter={RecipeUsage}
              darkMode={darkMode}
              onChange={handleRecipeUsageChange}
              value={recipeUsage}
            />
          </div>

          <div className="mb-4">
            <Label
              style={{ color: darkMode ? "white" : "black" }}
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Tipo
            </Label>
            <InfoComponent text="">
              <p>
                Uma receita simples é constituída apenas por ingredientes. Uma
                receita complexa tem, na sua composição, uma ou mais
                sub-receitas.
              </p>
            </InfoComponent>
            <SelectComponent
              formatter={recipeTypeFormatter}
              darkMode={darkMode}
              onChange={handleRecipeRecursiveChange}
              value={recipeRecursive}
            />

          </div>
          <div className="flex gap-6 w-full justify-end">
            <Button
              onClick={() => modalCloser(false)}
              type="submit"
              className="py-4 my-4 bg-red-400 hover:bg-red-500"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
              className="py-4 my-4"
            >
              Guardar receita
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeForm;
