// React
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../utils/contexts/theme.context";

// components
import Page from "../page";
import Loading from "../../../components/reusable/loading/loading";
import Toaster from "../../../components/reusable/Toaster";
import useTokenVerifier from "../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../Profile/usedarkmode";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { Button } from "../../../components/ui/button";
import { DatePicker } from "../../../components/ui/datePicker";

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";

// icons / images
import { MdSearch } from "react-icons/md";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { useLoading } from "../../../utils/contexts/LoadingContext";
import InfoComponent from "../../../components/reusable/info/info.component";
import { FcProcess } from "react-icons/fc";
import { FaTrash } from "react-icons/fa";

const initialColumns = [
  { headerName: "Descritivo Customer", field: "an_descr" },
  { headerName: "Estado", field: "an_obs" },
  { headerName: "Data de início", field: "an_dt_ini" },
  { headerName: "Data de fim", field: "an_dt_fim" },
  { headerName: "", field: "" },
];

const initialNewCustomerData = {
  name: "",
  description: "",
  start: new Date(new Date().setDate(new Date().getDate() - 7)),
  end: new Date(),
};

const CustomersAnalysis = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CUSTOMERS PAGE",
  });

  const { get, post } = useApi();
  const darkMode = useDarkMode();

  const [customers, setCustomers] = useState([]);
  const [last_sale_date, setLastSaleDate] = useState("");
  const { loading, setLoading } = useLoading();
  const [newCustomerData, setNewCustomerData] = useState(
    initialNewCustomerData
  );

  const setStart = (value: Date) => {
    setNewCustomerData((prevData) => ({
      ...prevData,
      start: value,
    }));
  };

  const setEnd = (value: Date) => {
    setNewCustomerData((prevData) => {
      const newEnd = value;
      const newStart = new Date(prevData.start);
      newStart.setDate(newStart.getDate() + 7); // Add one week

      if (newStart > newEnd) {
        newStart.setDate(newEnd.getDate() - 7); // Subtract one week
        Toaster.show(
          "Data de início alterada para uma semana antes da data de fim",
          "warning"
        );
      }

      return {
        ...prevData,
        end: newEnd,
        start: newStart,
      };
    });
  };

  const deleteItem = async (item) => {
      setLoading(true);
      try {
        const data = {
          an_id: item.an_id,
        };
        const response = await post(
          `/delete_analysis/`,
          data
        );
  
        if (response.status === 200) {
          Toaster.show("Análise apagada com sucesso.", "success");
          setCustomers((prevCustomers) =>
            prevCustomers.filter((customer) => customer.an_id !== item.an_id)
          );
        } else {
          Toaster.show("Erro ao apagar análise.", "error");
        }
      } catch (error) {
        console.error("Erro ao apagar análise:", error);
        Toaster.show("Erro ao apagar análise.", "error");
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const [analysisResponse, dateResponse] = await Promise.all([
          get("list_analysis"),
          get("dash4_sales_last_date"),
        ]);

        setCustomers(analysisResponse.data);
        console.log(analysisResponse.data);
        setLastSaleDate(dateResponse.data);
      } catch (error) {
        console.error(error);
        Toaster.show("Erro ao carregar análises de consumidor", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    setEnd(new Date(last_sale_date));
    setStart(
      new Date(new Date(last_sale_date).getTime() - 7 * 24 * 60 * 60 * 1000)
    );
  }, [last_sale_date]);

  const handleCreateCustomer = async () => {
    setLoading(true);
    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    const newCustomer = {
      an_descr: newCustomerData.name,
      an_obs: "Em progresso...",
      an_dt_ini: formatDate(newCustomerData.start),
      an_dt_fim: formatDate(newCustomerData.end),
    };

    try {
      await post(`customer_analisys/`, JSON.stringify(newCustomerData));
      Toaster.show("Análise de consumidor criada com sucesso!", "success");
      setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
      setIsDialogOpen(false);
      setNewCustomerData(initialNewCustomerData);
    } catch (error) {
      console.error("Erro ao criar análise:", error);
      Toaster.show("Erro ao criar análise de consumidor.", "error");
    }
    setLoading(false);
  };

  const renderCustomerTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {initialColumns.map((column) => (
            <TableCell key={column.field}>{column.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {customers.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.an_descr}</TableCell>
            <TableCell>{item.an_obs}</TableCell>
            <TableCell>{item.an_dt_ini}</TableCell>
            <TableCell>{item.an_dt_fim}</TableCell>

            <TableCell>
              <div className="flex gap-2">
                {item.an_obs.includes("Disponível") ? (
                  <MdSearch
                    className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                    title="Ver Análise"
                    color="black"
                    size={22}
                    onClick={() =>
                      navigate(`/app/customers/customer?id=${item.an_id}`)
                    }
                  />

                ) : (
                  <FcProcess
                    className="brightness-0"
                    title="Em Processamento"
                    size={22}
                  />
                )}
                <FaTrash
                  title="Eliminar"
                  className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                  onClick={() =>
                    Toaster.showWithHandler(
                      `Estará a eliminar a análise ${item.an_descr}. Deseja continuar?`,
                      () => deleteItem(item),
                      { duration: Infinity }
                    )
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page loading={loading}>
      {tokenVerified ? (
        <div className="w-[90%] mx-auto min-h-screen">
          <div className="flex justify-between items-center mb-5">
            <CardHeader>
              <CardTitle>Análise de Consumidores</CardTitle>
              <CardDescription>
                Crie e gerencie suas análises de consumidores
              </CardDescription>
            </CardHeader>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button className="mt-5">+ Criar Análise</Button>
              </DialogTrigger>
              <DialogContent className="min-w-[800px] max-h-[800px] overflow-auto">
                <DialogTitle>Criar Nova Análise de Consumidor</DialogTitle>
                <DialogDescription>
                  Preencha os campos para criar uma nova análise de consumidor
                </DialogDescription>
                <div className="flex flex-col gap-10 mt-4">
                  <div>
                    <Label className="block text-sm font-medium text-gray-700">
                      Nome
                    </Label>
                    <Input
                      type="text"
                      placeholder="Nome da Análise"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      value={newCustomerData.name}
                      onChange={(e) =>
                        setNewCustomerData({
                          ...newCustomerData,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <Label className="block text-sm font-medium text-gray-700">
                      Descrição
                    </Label>
                    <textarea
                      placeholder="Descrição da Análise"
                      className="w-full px-4 pt-2 rounded-md border border-gray bg-white"
                      value={newCustomerData.description}
                      onChange={(e) =>
                        setNewCustomerData({
                          ...newCustomerData,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div>
                    <InfoComponent>
                      <p>
                        A data de início é definida automaticamente para, no
                        mínimo, uma semana antes da data de fim.
                      </p>
                    </InfoComponent>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <Label className="block text-sm font-medium text-gray-700">
                        Data de início
                      </Label>
                      <DatePicker
                        date={newCustomerData.start}
                        setDate={setStart}
                        maxDate={
                          new Date(
                            new Date(last_sale_date).getTime() -
                            7 * 24 * 60 * 60 * 1000
                          )
                        } // set max date to one week before the end date                      />
                      />
                    </div>
                    <div className="flex-1">
                      <Label className="block text-sm font-medium text-gray-700">
                        Data de fim
                      </Label>
                      <DatePicker
                        date={newCustomerData.end}
                        setDate={setEnd}
                        maxDate={new Date(last_sale_date) || new Date()}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-2">
                  <Button
                    onClick={handleCreateCustomer}
                    disabled={
                      !newCustomerData.name || !newCustomerData.description
                    }
                  >
                    Criar
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          {!loading && <div>
            {customers && customers.length > 0 ? (
              renderCustomerTable()
            ) : (
              <div
                style={{ color: darkMode ? "white" : "black" }}
                className="text-center text-2xl mt-10"
              >
                Sem análises de consumidor. Crie uma nova análise.
              </div>
            )}
          </div>}
          <Loading isOpen={loading} />
        </div>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default CustomersAnalysis;
