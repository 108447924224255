import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import Toaster from "../../../../components/reusable/Toaster";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useLoading } from "../../../../utils/contexts/LoadingContext";
import { Avatar, AvatarFallback } from "../../../../components/ui/avatar";
import { ChevronLeft, ChevronRight, User } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface ProfileSetupProps {
  onPrevious?: () => void;
  onSubmit?: () => void;
  currentStep?: number;
}

const ProfileSetup: React.FC<ProfileSetupProps> = ({
  onPrevious,
  onSubmit,
  currentStep,
}) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { loading, setLoading } = useLoading();
  const [change, setChange] = useState(false);
  const [profile, setProfile] = useState<any>({});
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");

  const userInitials = `${first_name?.[0] || ""}${
    last_name?.[0] || ""
  }`.toUpperCase();

  useEffect(() => {
    const getProfile = async () => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get("profile");
        const profileData = Array.isArray(response.data)
          ? response.data[0]
          : response.data;
        setProfile(profileData);
        setFirst_name(profileData.first_name ?? "");
        setLast_name(profileData.last_name ?? "");
        setPhone(profileData.phone ?? "");
      } catch (error) {
        console.error("error", error);
      }
      setLoading(false);
    };

    getProfile();
  }, [change]);

  const isValidPhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, "PT");
    return phoneNumber ? phoneNumber.isValid() : false;
  };

  const handleProfileSubmit = async () => {
    setLoading(true);

    const data = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      setup: "COMPLETED",
    };

    if (!isValidPhoneNumber(phone)) {
      Toaster.show("Número de telemóvel inválido.", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        "/profile-update/",
        JSON.stringify(data)
      );

      if (response.status === 200 || response.status === 201) {
        Toaster.show("Perfil atualizado com sucesso.", "success");
        if (onSubmit) {
          onSubmit();
        }
        setChange(!change);
      } else {
        Toaster.show("Erro ao atualizar perfil.", "error");
      }
    } catch (error) {
      Toaster.show("Erro ao atualizar perfil.", "error");
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <Avatar className="h-20 w-20 bg-[#EAF3F4]">
            <AvatarFallback className="text-xl">{userInitials}</AvatarFallback>
          </Avatar>
          <Button variant="outline" size="sm">
            Alterar Avatar
          </Button>
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="firstName">Primeiro Nome</Label>
            <div className="relative">
              <Input
                id="firstName"
                placeholder="Insira o seu primeiro nome"
                className="pl-10"
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
              />
              <User className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="lastName">Último Nome</Label>
            <div className="relative">
              <Input
                id="lastName"
                placeholder="Insira o seu último nome"
                className="pl-10"
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
              />
              <User className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="phone">Nº Telemóvel</Label>
            <Input
              id="phone"
              type="tel"
              placeholder="+351"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
      </div>
      {currentStep && (
        <div className="flex justify-between mt-8">
          <Button
            variant="outline"
            onClick={onPrevious}
            disabled={currentStep === 1}
            className="flex items-center gap-2"
          >
            <ChevronLeft className="h-4 w-4" /> Anterior
          </Button>
          <Button
            onClick={handleProfileSubmit}
            className="bg-_secondary hover:bg-_primary flex items-center gap-2"
            disabled={loading || !first_name || !last_name || !phone}
          >
            Próximo
            {currentStep !== 3 && <ChevronRight className="h-4 w-4" />}
          </Button>
        </div>
      )}
      {!currentStep && (
        <div className="flex justify-end mt-8">
          <Button
            onClick={handleProfileSubmit}
            className="bg-_secondary hover:bg-_primary flex items-center gap-2"
            disabled={loading || !first_name || !last_name || !phone}
          >
            Guardar
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProfileSetup;
