class RecipeUsage {
  static usages = ["Receitas Internas(I)", "Outras Receitas(O)", "Receitas Menu(M)"];
  static default = "Receitas Internas(I)";

  static format(input: string): string {
    return input;
  }

  static getAll() {
    return this.usages;
  }

  static getFullString(code: string): string {
    if (code === undefined || code === null) {
      return this.default;
    }
    switch (code.toUpperCase()) {
      case 'I':
        return "Receitas Internas(I)";
      case 'O':
        return "Outras Receitas(O)";
      case 'M':
        return "Receitas Menu(M)";
      default:
        return this.default;
    }
  }
}
export default RecipeUsage;