import { useState, useEffect } from "react";
import Image from "./image";
import "react-multi-carousel/lib/styles.css";
import { useApi } from "../../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/customScroll.styles.css";
import { OrbitProgress } from "react-loading-indicators";

const News = ({ darkMode }) => {
  const { get, setCache, getCache } = useApi();
  const history = useNavigate();
  const [alreadyRedirect, setAlreadyRedirect] = useState(false);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("hotelaria");
  const [searchQuery, setSearchQuery] = useState("hotelaria");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionClass, setTransitionClass] = useState("");

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-PT");
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (params = {}) => {
      const url = params
        ? `scrape_news/?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `scrape_news`;

      try {
        setLoading(true);
        const cachedData = getCache(url);
        if (cachedData) {
          setNews(cachedData);
          setLoading(false);
          return;
        }
        const response = await get(url, {
          signal: controller.signal,
        });

        setNews(response.data);
        setCache(url, response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        switch (error.message) {
          case "Unauthorized":
            if (isMounted && !alreadyRedirect) {
              setAlreadyRedirect(true);
              history(-1);
            }
            break;
          default:
            console.error(error);
        }
      }
    };

    getGraph({ query: searchQuery });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchQuery]);

  if (loading || news.length === 0) {
    return (
      <div
        className={`relative h-96 w-full rounded-lg flex justify-center items-center ${
          darkMode ? "bg-purple-950 text-white" : "bg-white text-gray-800"
        }`}
      >
        <OrbitProgress
          color="black"
          size="medium"
          text=""
          textColor="#c428e4"
        />
      </div>
    );
  }

  return (
    <div className="relative h-96 w-full overflow-auto flex gap-2 pr-1">
      {news.length > 0 && (
        <div
          className={`transiton-all ease-in-out duration-500 h-40 ${transitionClass}`}
          style={{ transform: `translateY(-${currentIndex * 100}%)` }}
        >
          {news.map((item, index) => (
            <div
              key={index}
              className={`h-full flex items-center w-full p-6 ${
                index === 0 ? "mb-2" : "my-2"
              }my-2 border-b border-zinc-200 rounded ${
                darkMode ? "bg-purple-900 text-white" : "bg-white text-gray-800"
              }`}
            >
              <a href={item.link} className="flex gap-4 justify-between w-full">
                <div>
                  <h2 className="text-lg font-bold mb-2">{item.title}</h2>
                  <p>{formatDate(item.timestamp)}</p>
                </div>
                <Image
                  src={item.image_url}
                  alt={item.title}
                  className="rounded-lg w-20 h-16"
                />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default News;
