// React
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { DarkModeProvider } from "../../../../utils/contexts/theme.context";
import { useLoading } from "../../../../utils/contexts/LoadingContext";

// components
import Page from "../../page";
import Loading from "../../../../components/reusable/loading/loading";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import useTokenVerifier from "../../../../utils/hooks/useTokenVerifier";
import useDarkMode from "../../../Profile/usedarkmode";
import { useApi } from "../../../../utils/hooks/useAxiosPrivate";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import EventsCreate from "./EventsCreate";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../../../../components/ui/dialog";

// icons / images
import { MdSearch } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { FcProcess } from "react-icons/fc";

// interface Event {
//   an_id: number;
//   an_kpi_agre_type: string;
//   an_kpi_desc: string;
//   an_kpi_dt_ref_info: string;
//   an_kpi_filter_field_info: string;
//   an_kpi_filter_identif_info: string;
//   an_kpi_id: number;
//   an_kpi_name: string;
//   an_kpi_num_week_range_info: number;
//   an_kpi_qualit_type: string;
//   an_kpi_source_info: string;
//   an_kpi_time_field_info: string;
//   an_kpi_value_info: string;
//   zdata: string;
// }

interface EventListProps {
  an_id: number;
  an_nome: string;
  an_descr: string;
  an_obs: string;
  zdata: string;
  an_dt_fim: string;
  an_dt_ini: string;
  an_filter1: string; //KPI_AGREGADO OU GRANULAR
  an_filter2: string;
  an_filter3: string;
  an_filter4: string;
  an_kpi_id: number;
}

const EventsList = () => {
  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  // Callback function to update token verification status
  const handleTokenVerified = () => {
    setTokenVerified(true);
  };
  useTokenVerifier({
    onTokenVerified: handleTokenVerified,
    log: "CREATE EVENTS",
  });

  const { get, post } = useApi();
  const [events, setEvents] = useState<EventListProps[]>([]);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [change, setChange] = useState(false);
  const { loading, setLoading } = useLoading();

  const darkMode = useDarkMode();


  const deleteItem = async (item: EventListProps) => {
    setLoading(true);
    try {
      const data = {
        an_id: item.an_id,
      };
      const response = await post(
        `/delete_event/`,
        data
      );

      if (response.status === 200) {
        Toaster.show("Evento apagado com sucesso.", "success");
        setEvents(events.filter((e) => e.an_id !== item.an_id));
      } else {
        Toaster.show("Erro ao apagar evento.", "error");
      }
    } catch (error) {
      console.error("Erro ao apagar evento:", error);
      Toaster.show("Erro ao apagar evento.", "error");
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await get("list_events");
      console.log("fetchEvents response:", response.data);
      setEvents(response.data);
    } catch (error) {
      console.error(error);
      Toaster.show("Erro ao carregar listas de eventos", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, change]);

  const renderEventTable = () => (
    <Table>
      <TableHeader>
        <TableRow>
          {[
            "Nome",
            "Data de Criação",
            "Descritivo",
            // "Tipo",
            "Data do Evento",
            "Semanas a Considerar",
            "Estado",
          ].map((header) => (
            <TableCell key={header}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="text-gray-600">{item.an_nome}</TableCell>
            <TableCell className="text-gray-600">
              {item.zdata.substring(0, 16)}
            </TableCell>
            <TableCell className="text-gray-600">{item.an_descr}</TableCell>
            {/* <TableCell className="text-gray-600">
              {item.an_filter1}
            </TableCell> */}
            <TableCell className="text-gray-600">{item.an_dt_ini}</TableCell>
            {/* <TableCell className="text-gray-600">
              {item.an_filter3}
            </TableCell> */}
            <TableCell className="text-gray-600">
              {item.an_filter1 !== "" ? `${item.an_filter1} semanas` : ""}
            </TableCell>
            <TableCell>
              <div className="flex gap-2">
                {item.an_obs.includes("disponivel on") ? (
                  <MdSearch
                    className="hover:scale-125 transition-transform duration-30 text-gray-600 hover:text-gray-800 text-xl cursor-pointer bg-transparent"
                    onClick={() =>
                      navigate(`/app/events/detail/${item.an_kpi_id}`, {
                        state: {
                          an_id: item.an_kpi_id,
                          an_nome: item.an_nome,
                          an_descr: item.an_descr,
                        },
                      })
                    }
                    title="Ver Análise"
                    color="black"
                    size={22} />
                ) : (
                  <FcProcess
                    className="brightness-0"
                    title="Em Processamento"
                    size={22}
                  />
                )}
                <FaTrash
                  title="Eliminar"
                  className="text-red-300 hover:text-red-500 text-xl cursor-pointer hover:scale-125 transition-transform duration-30"
                  onClick={() =>
                    Toaster.showWithHandler(
                      `Estará a eliminar o evento ${item.an_nome}. Deseja continuar?`,
                      () => deleteItem(item),
                      { duration: Infinity }
                    )
                  }
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <Page title={"Criar Eventos"} loading={loading}>
      {tokenVerified ? (
        <DarkModeProvider>
          <div className="w-[90%] mx-auto h-full min-h-screen">
            <div className="flex justify-between items-center mb-5">
              <CardHeader>
                <CardTitle>Eventos</CardTitle>
                <CardDescription>
                  Crie eventos para analisar o seu negócio
                </CardDescription>
              </CardHeader>
              <Button className="mt-5" onClick={() => setShowModalCreate(true)}>
                + Criar Evento
              </Button>
              {showModalCreate && (
                <Dialog
                  open={showModalCreate}
                  onOpenChange={setShowModalCreate}
                >
                  <DialogContent className="min-w-[90%] xl:min-w-[1000px] max-h-[90%] overflow-auto">
                    <DialogTitle>Criar Novo Evento</DialogTitle>
                    <DialogDescription>
                      Preencha os campos para criar um novo evento
                    </DialogDescription>
                    <EventsCreate setShowModalCreate={setShowModalCreate} setChange={setChange} />
                  </DialogContent>
                </Dialog>
              )}
            </div>
            <div>
              {events.length > 0 ? (
                renderEventTable()
              ) : (
                <div
                  style={{ color: darkMode ? "white" : "black" }}
                  className="text-center text-2xl mt-10"
                >
                  Sem eventos. Crie um novo evento.
                </div>
              )}
            </div>
            <Loading isOpen={loading} />
          </div>
        </DarkModeProvider>
      ) : (
        // <Loading isOpen={true} />
        <></>
      )}
    </Page>
  );
};

export default EventsList;
