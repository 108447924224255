import React, { useEffect } from "react";
import ChartComponent from "./chartComponent";

const ChartFunc = ({
  chartRef,
  option,
  drilldown_data,
  func,
  handleChartFinished = null,
  ChangeScale = false,
  graphDescription = <p>Inserir descriçao</p>,
}) => {
  const colors = [
    "#CC33FF",
    "#9900CC",
    "#FF0066",
    "#FF66CC",
    "#FFCCFF",
    "#FF99CC",
    "#FF6699",
    "#FF3366",
    "#993399",
    "#CC3399",
    "#FF3399",
    "#FF6699",
    "#FF99AA",
    "#FF99BB",
    "#FF99DD",
    "#FF66DD",
    "#FF6699",
    "#FF3366",
    "#FF0033",
    "#FF3366",
  ]; //DO NOT DELETE

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const myChart = chartRef.current.getEchartsInstance();
      // const darkmode = darkMode; //DO NOT DELETE
      if (myChart) {
        const drilldownData = drilldown_data; //DO NOT DELETE

        eval(func);
      }
    }
  }, [chartRef, option, drilldown_data, func]); // darkMode

  return (
    <ChartComponent
      chartRef={chartRef}
      option={option}
      ChangeScale={ChangeScale}
      handleChartFinished={handleChartFinished}
    />
  );
};

export default ChartFunc;
