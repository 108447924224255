import * as React from "react";
import { cn } from "../../lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

interface DecimalInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  decimalPlaces?: number;
  min?: number;
  max?: number;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-12 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

const DecimalInput = React.forwardRef<HTMLInputElement, DecimalInputProps>(
  ({ decimalPlaces = 2, min, max, onChange, ...props }, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      
      // Allow empty input, minus sign, and decimal point
      if (value === '' || value === '-' || value === '.') {
        onChange?.(event);
        return;
      }

      // Validate decimal number format
      const regex = new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`);
      if (regex.test(value)) {
        const numValue = parseFloat(value);
        
        // Check min/max constraints if they exist
        if ((min === undefined || numValue >= min) && 
            (max === undefined || numValue <= max)) {
          onChange?.(event);
        }
      }
    };

    return (
      <Input
        type="number"
        step={`0.${"0".repeat(decimalPlaces - 1)}1`}
        min={min}
        max={max}
        onChange={handleChange}
        {...props}
        ref={ref}
      />
    );
  }
);

DecimalInput.displayName = "DecimalInput";


export { Input, DecimalInput };
