// React
import { useEffect } from "react";

// Hooks
import axiosPrivate from "../api/axios";
import useAuth from "./useAuth";
import { useUser } from "../../utils/contexts/UserContext";

const useTokenVerifier = ({ onTokenVerified, log }) => {
  const { auth } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    console.log("useEffect triggered with auth:", auth);

    if (!auth?.accessToken) {
      console.log("No access token");
      return;
    }

    const AUTH_URL = "/auth/jwt/verify";
    const LOGS_URL = "/create_log";
    console.log("AbortController and URLs initialized");

    const verifyToken = async () => {
      console.log("verifyToken function called");

      try {
        console.log("Attempting to verify token");
        const response = await axiosPrivate.post(
          AUTH_URL,
          {
            token: auth?.accessToken,
          },
        );
        console.log("response:", response);
        console.log("Token verification response:", response);

        if (response.status < 300) {
          console.log("Token verified successfully");
          onTokenVerified();
          const logData = {
            username: user?.username,
            log_type: log,
            log_oper_status: "SUCCESS",
            log_oper_descr: "teste",
            log_obs: "localhost",
          };
          console.log("Log data prepared:", logData);

          await axiosPrivate.post(LOGS_URL, logData, {
            headers: {
              Authorization: `Bearer ${auth?.accessToken}`,
            },
          });
          console.log("Log data sent successfully");
        }
      } catch (error) {
        console.error("Error refreshing token (tokenVerifier): ", error);
        console.log("Token verification failed");
        /*const logData = {
          username: 'Anonymous',
          log_type: { log },
          log_oper_status: 'TOKEN INVALID',
          log_oper_descr: 'teste',
          log_obs: 'dummyObs',
        };
        const responseLogs = await axiosPrivate.post(LOGS_URL, logData);*/
        //history.goBack();
        //window.location.reload();
      }
    };

    verifyToken();

    return () => {
      console.log("Cleanup: aborting controller");
    };
  }, [user, auth?.accessToken]);
};

export default useTokenVerifier;
