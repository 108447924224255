import React, { useState } from "react";
import ProfileSetup from "./ProfileSetup";
import RestaurantSetup from "./RestaurantSetup";
import PricingTable from "../../../../components/reusable/PricingTable";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const steps = ["Criar Perfil", "Criar Restaurante", "Escolher Plano"];

const UserSetup: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ProfileSetup
            currentStep={currentStep}
            onSubmit={handleNext}
            onPrevious={handlePrevious}
          />
        );
      case 2:
        return (
          <RestaurantSetup
            onPrevious={handlePrevious}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <div className="h-[70vh] flex items-center justify-center">
            <PricingTable />
          </div>
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  return (
    <div className="min-h-screen w-full bg-light flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-4xl bg-white rounded-[20px] shadow-[0px_3px_8px_rgba(20,27,52,0.04)] p-8"
      >
        <div className="mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold">
              Passo {currentStep} de {steps.length}
            </h2>
            <div className="flex items-center gap-2 text-sm text-[#747474]">
              <span className="font-medium">{steps[currentStep - 1]}</span>
            </div>
          </div>
          <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-_primary transition-all duration-300"
              style={{ width: `${(currentStep / steps.length) * 100}%` }}
            />
          </div>
        </div>

        {renderStep()}
      </motion.div>
    </div>
  );
};

export default UserSetup;
