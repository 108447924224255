// React
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Hooks
import useAxiosPrivate from "../../../../utils/hooks/useAxiosPrivate";
import useDarkMode from "../../../Profile/usedarkmode";

// Components
import InfoComponent from "../../../../components/reusable/info/info.component";
import Loading from "../../../../components/reusable/loading/loading";
import Paginate from "../../../../components/reusable/pagination/paginate";
import Toaster from "../../../../components/reusable/Toaster";
import { Button } from "../../../../components/ui/button";
import SearchBar from "../../../../components/ui/searchbar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { Input } from "../../../../components/ui/input";

// Icons
import { FaSearch } from "react-icons/fa";

// Other
import toast from "react-hot-toast";
import { DialogTitle } from "../../../../components/ui/dialog";

const IngredCreateModal = ({
  assign_id = 0,
  modalCloser,
  setChange,
  change,
  recipeName,
  recipeId = "",
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  recipeId = recipeId || queryParams.get("id");
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(recipeName.split(" ")[0]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("g");
  const [loading, loadingSetter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [searchItem, setSearchItem] = useState("");

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getGraph = async (endpoint, params = {}) => {
      loadingSetter(true);
      const url = params
        ? `${endpoint}/?${Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`
        : `${endpoint}`;
      try {
        const response = await axiosPrivate.get(url, {
          signal: controller.signal,
        });

        if (!response.data.message) {
          toast(
            "A sua pesquisa não retornou resultados. Sugerimos as abaixo.",
            {
              icon: (
                <FaSearch
                  style={{
                    color: darkMode ? "white" : "black",
                    fontSize: "50px",
                  }}
                />
              ),
            }
          );
        }

        if (response.data.results.data) {
          setShow(true);
        }

        setData(response.data.results.data);

        setTotalPages(response.data.results.total_pages);
        loadingSetter(false);
      } catch (error) {
        loadingSetter(false);
        console.error(error);
      }
    };
    getGraph("ingred_list", {
      search: searchItem,
      page: currentPage,
      recipeName: recipeName,
    });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [searchItem, currentPage]);

  const handleCheckboxChange = (ingr) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      if (prevSelectedIngredients.find((item) => item.id === ingr.ingr_id)) {
        return prevSelectedIngredients.filter(
          (item) => item.id !== ingr.ingr_id
        );
      } else {
        return [
          ...prevSelectedIngredients,
          { id: ingr.ingr_id, quantity: 0, order: ingr.ingr_order },
        ];
      }
    });
  };

  const handleQuantityChange = (ingr_id, quantity) => {
    setSelectedIngredients((prevSelectedIngredients) => {
      return prevSelectedIngredients.map((item) =>
        item.id === ingr_id ? { ...item, quantity } : item
      );
    });
  };

  const handleAssign = async () => {
    loadingSetter(true);
    if (selectedIngredients.length === 0) {
      Toaster.show("Nenhum ingrediente selecionado", "error");
      loadingSetter(false);
      return;
    }
    for (const ingredient of selectedIngredients) {
      const ingredToAssign = {
        assign_id: assign_id,
        recipe: recipeId,
        ingr: ingredient.id,
        ingr_qt: ingredient.quantity,
        ingr_qt_unit: selectedUnit, // Provide a value for this field
        ingr_order: ingredient.order,
      };
      try {
        const response = await axiosPrivate.post(
          `/assign_create_or_update/`,
          JSON.stringify(ingredToAssign)
        );

        if (response.status === 200 || response.status === 201) {
          Toaster.show("Associação criada com sucesso.", "success");
          setChange(!change);
        } else {
          Toaster.show("Erro ao criar associação.", "error");
          loadingSetter(false);
          break;
        }
      } catch (error) {
        console.error("Error:", error);
        Toaster.show("Erro ao criar associação.", "error");
        loadingSetter(false);
      }
    }
    setChange(!change);
  };
  const handleUnitChange = (newUnit) => {
    setSelectedUnit(newUnit);
  };
  const darkMode = useDarkMode();

  return (
    <>
    <DialogTitle className="text-xl">Associação de Ingredientes</DialogTitle>
        <CardHeader>
        
        <CardDescription>Associe ingredientes à sua receita.</CardDescription>
      </CardHeader>

      <InfoComponent text="">
        <p>
          Pesquise e associe ingredientes à sua receita. Forneça informação
          relativamente às quantidades utilizadas na confeção da receita.
        </p>
      </InfoComponent>

      <div className="mt-4 mb-1">
        <SearchBar
          search={search}
          setSearch={setSearch}
          setFetchSearch={setSearchItem}
        />
      </div>
      {data.length > 0 && show && (
        <Table className="border border-gray-200 rounded-lg">
          <TableHeader>
            <TableRow>
              <TableHead>Nome</TableHead>
              <TableHead>Classe</TableHead>
              <TableHead>Quantidade</TableHead>
              <TableHead>Unidade</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((ingredient) => (
              <TableRow key={ingredient.ingr_id}>
                <TableCell>{ingredient.ingr_name}</TableCell>
                <TableCell>{ingredient.ingr_category}</TableCell>

                <TableCell>
                  {selectedIngredients.find(
                    (item) => item.id === ingredient.ingr_id
                  ) !== undefined && (
                    <Input
                      placeholder="Quantidade"
                      className="h-9"
                      type="number"
                      value={
                        selectedIngredients.find(
                          (item) => item.id === ingredient.ingr_id
                        )?.quantity || ""
                      }
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        handleQuantityChange(ingredient.ingr_id, value);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {selectedIngredients.find(
                    (item) => item.id === ingredient.ingr_id
                  ) !== undefined && (
                    <Select>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Unidade" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="g">Gramas</SelectItem>
                        <SelectItem value="ml">Mililitros</SelectItem>
                        <SelectItem value="u">Unidade</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                </TableCell>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={
                      selectedIngredients.find(
                        (item) => item.id === ingredient.ingr_id
                      ) !== undefined
                    }
                    onChange={() => handleCheckboxChange(ingredient)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <div className="flex justify-center">
        <Paginate
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          loading={!data}
        />
      </div>

      <div className="flex justify-end mt-2">
        <Button
          type="submit"
          onClick={() => {
            modalCloser(false);
            handleAssign();
          }}
        >
          Guardar Associação
        </Button>
      </div>
      <Loading isOpen={loading} />
    </>
  );
};

export default IngredCreateModal;
